import { atom } from 'recoil';
import { Types } from '../../components/pages/DashboardPage/types';
import { MetricTypeEnum } from '../../components/pages/DashboardPage/WidgetsType';
import { DashboardModel } from '../../models';
import { DashboardFilters } from '../../types/dashboard';
import { persistAtom } from './userAtoms';

export const propertiesState = atom<Types.Data[] | [] | undefined>({
  key: 'PropertiesState',
  default: undefined,
  effects: [persistAtom],
});

export const leasesState = atom<Types.Data[] | [] | undefined>({
  key: 'LeasesState',
  default: undefined,
  effects: [persistAtom],
});

export const selectedDropDownItemState = atom<string>({
  key: 'SelectedDropDownItemState',
  default: '',
  effects: [persistAtom],
});

export const filteredDataState = atom<Types.Data[] | []>({
  key: 'FilteredDataState',
  default: [],
  effects: [persistAtom],
});

export const dashboardsFiltersState = atom<DashboardFilters[] | []>({
  key: 'DashboardsFiltersState',
  default: [],
  effects: [persistAtom],
});

export const accessorsState = atom<Accessors>({
  key: 'AccessorsState',
  default: {
    types: [MetricTypeEnum.X_ACCESSOR, MetricTypeEnum.Y_ACCESSOR, MetricTypeEnum.Z_ACCESSOR],
    values: ['', '', ''],
    originatingElement: '',
  },
  effects: [persistAtom],
});

export const dashboardModelState = atom<DashboardModel | undefined>({
  key: 'DashboardState',
  default: undefined,
  effects: [persistAtom],
});
