import * as React from 'react';
import { Dispatch, SetStateAction, useState } from 'react';
import MuiMenu, { MenuProps } from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { TargetNodeWrapper } from './Menu.css';

export interface MenuOption {
  id: string;
  label: string;
  value: string;
}

interface ClientDialogPanelProps {
  options: MenuOption[];
  onClickItem: (id: string) => void;
  targetNode: React.ReactNode;
}

interface MenuTargetComponentProps {
  targetNode: React.ReactNode;
  setAnchorEl: Dispatch<SetStateAction<(EventTarget & HTMLElement) | null>>;
}

const MenuTargetComponent = ({ targetNode, setAnchorEl }: MenuTargetComponentProps) => {
  return (
    <TargetNodeWrapper onClick={(e) => setAnchorEl(e.currentTarget)}>
      {targetNode}
    </TargetNodeWrapper>
  );
};

export const Menu = ({
  options,
  onClickItem,
  targetNode,
  ...rest
}: ClientDialogPanelProps & Omit<MenuProps, 'open'>) => {
  const [clientAnchorEl, setClientAnchorEl] = useState<(EventTarget & HTMLElement) | null>(null);

  const open = Boolean(clientAnchorEl);
  const handleClose = () => {
    setClientAnchorEl(null);
  };

  return (
    <>
      <MenuTargetComponent setAnchorEl={setClientAnchorEl} targetNode={targetNode} />
      <MuiMenu
        id="menu"
        anchorEl={clientAnchorEl}
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        {...rest}
      >
        {options.map((client) => {
          return (
            <MenuItem key={client.label} onClick={() => onClickItem(client.id)}>
              {client.label}
            </MenuItem>
          );
        })}
      </MuiMenu>
    </>
  );
};
