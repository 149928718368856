import { toRelativeUrl } from '@okta/okta-auth-js';
import { useRouter } from 'next/router';
import { useRecoilState } from 'recoil';
import { ROUTES_PATH_URLS } from 'components/routing/constants/routes';
import { userModelState } from 'recoil/atoms/userAtoms';
import oktaAuthentication from 'utils/auth/oktaAuthentication';
import Snackbar from 'components/shared/Toaster/ToasterWithoutState';
import { NextPublicEnvironmentEnum } from 'enums/nextPublicEnvironmentEnum';
import logger from 'utils/logger';
import { addDataAsCookie, deleteAllCookies, getDataFromCookie } from '../utils/cookies';
import { INIT_USER } from '../../../models';

export const OKTA_ACCESS_TOKEN = 'okta-access-token';

const ERROR_MESSAGES = {
  SIGN_IN_FAIL: 'Sign in fail',
  OKTA_REDIRECT: 'Error occurred during Okta redirect',
  OKTA_SDK_AUTH_FAILED: 'Error Okta SDK getToken failed',
};

interface Credentials {
  username: string;
  password: string;
}

function useOktaAuth() {
  const router = useRouter();
  const [user, setUser] = useRecoilState(userModelState);

  const signInWithCredentials = async (credentials: Credentials) => {
    await oktaAuthentication?.auth.closeSession();
    oktaAuthentication?.auth.tokenManager.clear();

    const result = await oktaAuthentication?.auth
      .signInWithCredentials(credentials)
      .then((res) => {
        if (res?.sessionToken) {
          oktaAuthentication?.auth.token
            .getWithoutPrompt({
              sessionToken: res.sessionToken!,
              responseType: ['token', 'id_token'],
            })
            .then((response) => {
              const tokens = response?.tokens;
              if (tokens?.accessToken?.accessToken) {
                oktaAuthentication?.auth.tokenManager.add(
                  tokens.accessToken.claims.sub,
                  tokens.accessToken
                );
              }
            });
        }
        return res;
      })
      .catch((err) => {
        Snackbar.error(`${ERROR_MESSAGES.SIGN_IN_FAIL}: ${err.message}`);
      });
    return result;
  };

  const signInWithRedirect = () => {
    const originalUri = {
      originalUri: toRelativeUrl(window.location.href, window.location.origin),
    };

    return oktaAuthentication?.auth
      .signInWithRedirect(originalUri)
      .then((res) => res)
      .catch((err) => {
        Snackbar.error(`${ERROR_MESSAGES.OKTA_REDIRECT}: ${err.message}`);
      });
  };

  const findTokenAndReturnAccessToken = async () => {
    const tokens = (await getDataFromCookie(OKTA_ACCESS_TOKEN)) ?? undefined;
    if (tokens) {
      const retAccessToken = tokens.accessToken.accessToken;
      logger(
        `useOktaAuth.ts :: findTokenAndReturnAccessToken cookie retAccessToken: ${retAccessToken}`
      );
      return retAccessToken;
    }

    try {
      const response = await oktaAuthentication?.auth.token.getWithoutPrompt({
        sessionToken: '',
        responseType: ['token', 'id_token'],
      });

      if (response?.tokens?.accessToken?.accessToken) {
        const retAccessToken = response.tokens.accessToken.accessToken;
        oktaAuthentication?.auth.tokenManager.add(
          response.tokens.accessToken.claims.sub,
          response.tokens.accessToken
        );
        logger(`useOktaAuth.ts :: API retAccessToken: ${retAccessToken}`);
        return retAccessToken;
      }
    } catch (err) {
      logger(
        `useOktaAuth.ts :: ${ERROR_MESSAGES.OKTA_SDK_AUTH_FAILED}: ${JSON.stringify(err) ?? ''}`
      );
    }

    return undefined;
  };

  const closeOktaSession = () => {
    // Clear localStorage to remove any user-specific data
    localStorage.clear();

    // Clear any stored tokens from the token manager
    oktaAuthentication?.auth.tokenManager.clear();

    deleteAllCookies();

    // Close the user's session
    return oktaAuthentication?.auth.closeSession().catch((err) => {
      logger(`useOktaAuth.ts :: Error closing Okta session: ${err}`);
    });
  };

  const closeSessionAndSignInWithRedirect = async () => {
    await closeOktaSession();
    if (process.env.NEXT_PUBLIC_ENVIRONMENT === NextPublicEnvironmentEnum.LOCAL_SKIP_OKTA) {
      return router.push(ROUTES_PATH_URLS.okta_offline_callback);
    }
    await router.push(ROUTES_PATH_URLS.account_sign_in);
  };

  const isTokenExpired = async () => {
    if (process.env.NEXT_PUBLIC_ENVIRONMENT === 'local_skip_okta') {
      logger('useOktaAuth.ts :: local_skip_okta');
      addDataAsCookie({ accessToken: { accessToken: 'local_skip_okta' } }, OKTA_ACCESS_TOKEN, 8);
      return false;
    }

    const retAccessToken = await findTokenAndReturnAccessToken();
    const tokens = oktaAuthentication?.auth.tokenManager.getTokensSync();
    if (tokens?.accessToken) {
      addDataAsCookie(tokens, OKTA_ACCESS_TOKEN, 12);
    }

    const hasExpired = !retAccessToken;
    logger(
      `useOktaAuth.ts :: isTokenExpired :: hasExpired: ${hasExpired}, accessToken: ${retAccessToken}`
    );
    return hasExpired;
  };

  const isNotAuthorizedRoute = () => {
    const pathname = router.pathname;
    const isNotAllowedCurrentPathname =
      pathname !== ROUTES_PATH_URLS.account_unauthorized &&
      pathname !== ROUTES_PATH_URLS.account_sign_out &&
      pathname !== '/' &&
      !pathname.includes('okta/callback') &&
      !pathname.includes('something-went-wrong');

    return !user.isAuth && isNotAllowedCurrentPathname;
  };

  const signOut = async () => {
    closeOktaSession();
    setUser(INIT_USER);
  };

  return {
    client: oktaAuthentication?.auth,
    signInWithCredentials,
    signInWithRedirect,
    findTokenAndReturnAccessToken,
    closeSession: closeOktaSession,
    closeSessionAndSignInWithRedirect,
    isNotAuthorizedRoute,
    isTokenExpired,
    signOut,
  };
}

export default useOktaAuth;
