import { selector } from 'recoil';
import { setRecoil } from 'recoil-nexus';
import { request } from '../../../pages/api/fetchService';
import Snackbar from '../../components/shared/Toaster/ToasterWithoutState';
import { HttpMethod } from 'enums/httpMethodEnum';
import { IDeletedResponse } from 'interfaces';
import { ClientModel, UserClientModel, UserModel } from 'models';
import {
  CreateClientDto,
  IUserClientsWithUsersDeletedUuids,
  UpdateClientWithAssignedUsersDto,
} from 'types/clients';
import { adminClientsState } from '../atoms/adminAtoms';
import { userModelState } from '../atoms/userAtoms';
import { getClientDashboardsDataRequest } from './clientDashboardSelectors';
import { getURLByEndpoint } from './SelectorsHelper';
import { API_PATH } from 'components/routing/constants/api';
import { formatClientDashboardsWithBookmarks } from 'utils/cliendDashboardHelper';
import {
  resetPropertiesAndLeasesState,
  updateUserActiveClientRequest,
} from 'recoil/selectors/usersSelectors';
import { dashboardsFiltersState } from 'recoil/atoms/dashboardsAtoms';
import { getUserClientByActiveClientUuid } from 'utils/userHelper';

export const CLIENT_SUCCESS_MESSAGES = {
  CREATED: 'Client successfully created',
  UPDATED: 'Client successfully updated',
  DELETED: 'Client  with assigned users successfully deleted',
};

export const CLIENT_WARNING_MESSAGES = {
  NOT_FOUND: 'Client was not found in list',
};

export const getClientsSelector = selector({
  key: 'GetClientsSelector',
  get: async (): Promise<ClientModel[]> => {
    const res = await request<ClientModel[]>(
      getURLByEndpoint(API_PATH.client.getClientsWithUserClients),
      HttpMethod.GET
    );
    setRecoil(adminClientsState, res);
    return res;
  },
});

export const createClientRequest = async (dto: CreateClientDto): Promise<ClientModel> => {
  const res = await request<ClientModel>(
    getURLByEndpoint(API_PATH.client.createOne),
    HttpMethod.POST,
    dto
  );
  Snackbar.success(CLIENT_SUCCESS_MESSAGES.CREATED);
  return res;
};

export const updateClientWithAssignedUsersRequest = async (
  clientUuid: string,
  dto: UpdateClientWithAssignedUsersDto
): Promise<ClientModel> => {
  const res = await request<ClientModel>(
    getURLByEndpoint(API_PATH.client.updateOne, clientUuid),
    HttpMethod.PATCH,
    {
      ...dto,
      userClientsDeletedUuids:
        dto.userClientsWithUsersDeletedUuids?.map((uc) => uc.userClientDeletedUuid ?? undefined) ??
        [],
    }
  );
  Snackbar.success(CLIENT_SUCCESS_MESSAGES.UPDATED);
  return res;
};

export const deleteClientWithAssignedUsersRequest = async (
  clientUuid: string
): Promise<IDeletedResponse<ClientModel>> => {
  const res = await request<IDeletedResponse<ClientModel>>(
    getURLByEndpoint(API_PATH.client.deleteOne, clientUuid),
    HttpMethod.DELETE
  );
  Snackbar.success(CLIENT_SUCCESS_MESSAGES.DELETED);
  return res;
};

export const createClientSelector = selector({
  key: 'CreateClientSelector',
  get: ({ getCallback, get }) => {
    const adminClientModels = get(adminClientsState);

    return getCallback(() => async (dto: CreateClientDto) => {
      const createdClientModel = await createClientRequest(dto);
      setRecoil(adminClientsState, [...adminClientModels, createdClientModel]);
      return createdClientModel;
    });
  },
});

const updateClientDashboardsIfNeeded = async (
  userModel: UserModel,
  newSelectedUserClientModel: UserClientModel | undefined,
  newUserClientModels: UserClientModel[]
) => {
  if (!newSelectedUserClientModel || !newUserClientModels.length) return [];

  const newClientDashboardModels =
    newSelectedUserClientModel.clientModel.clientUuid !==
    userModel.selectedUserClientModel?.clientModel.clientUuid
      ? await getClientDashboardsDataRequest(newSelectedUserClientModel.clientModel.clientUuid)
      : userModel.clientDashboardModels ?? [];

  return formatClientDashboardsWithBookmarks(
    newClientDashboardModels,
    userModel.clientDashboardBookmarkModels
  );
};

export const updateClientWithAssignedUsersSelector = selector({
  key: 'UpdateClientWithAssignedUsersSelector',
  get: ({ getCallback, get }) => {
    const userModel = get(userModelState);
    const adminClientModels = get(adminClientsState);
    const updateClient = async (clientUuid: string, dto: UpdateClientWithAssignedUsersDto) => {
      const updatedClientModel = await updateClientWithAssignedUsersRequest(clientUuid, dto);

      const index = adminClientModels.findIndex(
        (ac) => ac.clientUuid === updatedClientModel.clientUuid
      );
      if (index === -1) {
        Snackbar.warning(CLIENT_WARNING_MESSAGES.NOT_FOUND);
        return;
      } else {
        const updatedAdminClientModels = [...adminClientModels];
        updatedAdminClientModels[index] = updatedClientModel;
        setRecoil(adminClientsState, updatedAdminClientModels);
      }
      return updatedClientModel;
    };

    const updateSelectedUserClient = (
      updatedClientModel: ClientModel,
      newUserClientModels: UserClientModel[] | []
    ) => {
      if (!newUserClientModels.length) return undefined;

      //Case if current user was deleted from assigned users
      const hasUserClientModelsChanged =
        newUserClientModels?.length !== userModel.userClientModels?.length;

      if (hasUserClientModelsChanged) {
        const newCurrentUserClient =
          getUserClientByActiveClientUuid(newUserClientModels, userModel.activeClientUuid) ??
          newUserClientModels[0];

        updateUserActiveClientRequest(
          userModel.userUuid,
          newCurrentUserClient.clientModel.clientUuid
        );

        return newCurrentUserClient;
      }

      const isSelectedClientUpdated =
        userModel.selectedUserClientModel?.clientModel.clientUuid === updatedClientModel.clientUuid;

      return userModel.selectedUserClientModel
        ? {
            ...userModel.selectedUserClientModel,
            currencyCode: isSelectedClientUpdated
              ? updatedClientModel.currencyCodeDefault!
              : userModel.selectedUserClientModel.currencyCode,
            clientModel: isSelectedClientUpdated
              ? updatedClientModel
              : userModel.selectedUserClientModel.clientModel,
          }
        : undefined;
    };

    const updateUserClients = (
      updatedClientModel: ClientModel,
      userClientsWithUsersDeletedUuids?: IUserClientsWithUsersDeletedUuids[]
    ) => {
      const updatedUserClientModels = userModel.userClientModels;

      //Case if current user was deleted from assigned users
      if (
        userClientsWithUsersDeletedUuids
          ?.map((ucd) => ucd.userDeletedUuid)
          .includes(userModel.userUuid)
      ) {
        const currentUserClientUuid = userClientsWithUsersDeletedUuids.find(
          (uc) => uc.userDeletedUuid === userModel.userUuid
        )?.userClientDeletedUuid;

        return updatedUserClientModels.filter((uc) => uc.userClientUuid !== currentUserClientUuid);
      }

      return updatedUserClientModels.map((uc) =>
        uc.clientModel.clientUuid === updatedClientModel.clientUuid
          ? {
              ...uc,
              currencyCode: updatedClientModel.currencyCodeDefault!,
              clientModel: updatedClientModel,
            }
          : uc
      );
    };

    const updateSelectedUserClientAndUserClientsAndClientDashboards = async (
      updatedClientModel: ClientModel,
      userClientsWithUsersDeletedUuids?: IUserClientsWithUsersDeletedUuids[]
    ) => {
      const userClientModels = updateUserClients(
        updatedClientModel,
        userClientsWithUsersDeletedUuids
      );
      const selectedUserClientModel = updateSelectedUserClient(
        updatedClientModel,
        userClientModels
      );
      const clientDashboardModels = await updateClientDashboardsIfNeeded(
        userModel,
        selectedUserClientModel,
        userClientModels
      );

      setRecoil(userModelState, (prevUserModel) => ({
        ...prevUserModel,
        activeClientUuid: selectedUserClientModel?.clientModel.clientUuid ?? '',
        clientDashboardModels,
        userClientModels,
        selectedUserClientModel,
      }));
      return selectedUserClientModel;
    };

    return getCallback(() => async (clientUuid: string, dto: UpdateClientWithAssignedUsersDto) => {
      const updatedClientModel = await updateClient(clientUuid, dto);
      if (updatedClientModel) {
        await updateSelectedUserClientAndUserClientsAndClientDashboards(
          updatedClientModel,
          dto.userClientsWithUsersDeletedUuids
        );

        return updatedClientModel;
      }
    });
  },
});

export const deleteClientWithAssignedUsersSelector = selector({
  key: 'DeleteClientWithAssignedUsersSelector',
  get: ({ getCallback, get }) => {
    const userModel = get(userModelState);

    const deleteClient = async (clientUuid: string) => {
      const res = await deleteClientWithAssignedUsersRequest(clientUuid);
      setRecoil(adminClientsState, (prevAdminClients) =>
        prevAdminClients.filter((ac) => ac.clientUuid !== res.deletedEntity.clientUuid)
      );
      return res;
    };

    const updateSelectedUserClientAndUserClientsAndClientDashboards = async (
      userClientModels: UserClientModel[]
    ) => {
      const selectedUserClientModel =
        userModel.selectedUserClientModel && userClientModels.length
          ? {
              ...userModel.selectedUserClientModel,
              clientModel:
                getUserClientByActiveClientUuid(userClientModels, userModel.activeClientUuid)
                  ?.clientModel ?? userClientModels[0].clientModel,
            }
          : undefined;

      if (selectedUserClientModel) {
        updateUserActiveClientRequest(
          userModel.userUuid,
          selectedUserClientModel?.clientModel.clientUuid
        );
      }

      const clientDashboardModels = await updateClientDashboardsIfNeeded(
        userModel,
        selectedUserClientModel,
        userClientModels
      );

      setRecoil(userModelState, (prevUserModel) => ({
        ...prevUserModel,
        userClientModels,
        selectedUserClientModel,
        clientDashboardModels,
        activeClientUuid: selectedUserClientModel?.clientModel.clientUuid ?? '',
      }));
    };

    return getCallback(() => async (clientUuid: string) => {
      const res = await deleteClient(clientUuid);
      const filteredUserClientModels = userModel.userClientModels.filter(
        (uc) => uc.clientModel.clientUuid !== clientUuid
      );
      await updateSelectedUserClientAndUserClientsAndClientDashboards(filteredUserClientModels);
      return res;
    });
  },
});

export const updateClientSelector = selector({
  key: 'UpdateUserActiveUserClient',
  get: ({ getCallback, get }) => {
    const userModel = get(userModelState);
    return getCallback(() => (activeClientUuid: string) => {
      const userClientModel = userModel.userClientModels?.find(
        (uc) => uc.clientModel.clientUuid === activeClientUuid
      );
      if (!userClientModel) {
        Snackbar.warning(CLIENT_WARNING_MESSAGES.NOT_FOUND);
        return;
      }
      updateUserActiveClientRequest(userModel.userUuid, activeClientUuid);
      setRecoil(userModelState, (prevUserModel) => ({
        ...prevUserModel,
        activeClientUuid,
        selectedUserClientModel: userClientModel,
      }));
      setRecoil(dashboardsFiltersState, []);
      resetPropertiesAndLeasesState();
    });
  },
});
