import { ClientDashboardModel } from 'models';
import { ClientDashboardBookmarkModel } from 'models/clientDashboardBookmark';

export const formatClientDashboardsWithBookmarks = (
  clientDashboardModels: ClientDashboardModel[],
  clientDashboardBookmarkModels: ClientDashboardBookmarkModel[] | []
) => {
  return clientDashboardModels.map((cd) =>
    clientDashboardBookmarkModels.find((cdb) => cdb.clientDashboardUuid === cd.clientDashboardUuid)
      ? { ...cd, bookmarked: true }
      : { ...cd, bookmarked: false }
  );
};
