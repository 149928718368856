import { v4 as uuid } from 'uuid';
import { PermissionStatusEnum } from 'components/routing/enums/userClientPermissionEnum';
import { INIT_USER_CLIENT_PERMISSIONS } from 'components/routing/constants/permissions';
import { IUserClientPermission } from 'models';

export const getPermissionNameFromPathName = (pathname: string) => {
  let permissionNameBasedOnPathname = '';

  const userClientPermissionItem = INIT_USER_CLIENT_PERMISSIONS.find(
    (p) => p.permissionUrl && pathname.toLowerCase().includes(p.permissionUrl.toLowerCase())
  );

  if (userClientPermissionItem) {
    permissionNameBasedOnPathname = userClientPermissionItem.name;
  }

  return permissionNameBasedOnPathname;
};

export const parsePermissionString = (
  userClientUuid: string,
  permissionValue?: string
): IUserClientPermission[] => {
  if (!permissionValue) {
    return [];
  }

  const validStatusValues = [
    PermissionStatusEnum.NONE,
    PermissionStatusEnum.VIEW,
    PermissionStatusEnum.VIEW_OR_EDIT,
  ];

  return permissionValue.split('|').map((item) => {
    const [name, status] = item.split(':') as [name: string, status: PermissionStatusEnum];
    const index = validStatusValues.indexOf(status);
    const validStatus = index >= 0 ? validStatusValues[index] : PermissionStatusEnum.NONE;
    return {
      uuid: uuid(),
      status: validStatus,
      userClientUuid,
      name,
    };
  });
};
