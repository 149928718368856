import React, { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import useOktaAuth from 'components/routing/hooks/useOktaAuth';
import Snackbar from '../../shared/Toaster/ToasterWithoutState';
import logger from 'utils/logger';
import { ROUTES_PATH_URLS } from '../constants/routes';

const ERROR_MESSAGES = {
  TOKEN_OR_NOT_AUTHORIZED: 'You token expired and you are not authorized to view this page',
  TOKEN_EXPIRE: 'Expire Token, redirect to sign-in',
  NOT_AUTHORIZED: 'You are not authorized to view this page',
};

export function AuthCheck({ children }: { children: React.ReactNode }) {
  const router = useRouter();
  const [isRenderPages, setIsRenderPages] = useState(false);

  const { isTokenExpired, isNotAuthorizedRoute, closeSessionAndSignInWithRedirect } = useOktaAuth();

  useEffect(() => {
    // Ignore sign-in page
    const isSignInPage = router.pathname.includes(ROUTES_PATH_URLS.account_sign_in);
    if (isSignInPage) {
      setIsRenderPages(true);
      return;
    }

    setIsRenderPages(false);

    const checkAuthAndTokenExpiration = async () => {
      const isNotAuthorizedRouteValue = await isNotAuthorizedRoute();
      const isTokenExpiredValue = await isTokenExpired();
      logger(
        `AuthCheck.tsx :: isTokenExpiredValue: ${isTokenExpiredValue}, isNotAuthorizedRouteValue: ${isNotAuthorizedRouteValue}`
      );
      switch (true) {
        case isNotAuthorizedRouteValue && isTokenExpiredValue:
          Snackbar.error(ERROR_MESSAGES.TOKEN_OR_NOT_AUTHORIZED);
          return closeSessionAndSignInWithRedirect();
        case isNotAuthorizedRouteValue:
          Snackbar.error(ERROR_MESSAGES.NOT_AUTHORIZED);
          return closeSessionAndSignInWithRedirect();
        case isTokenExpiredValue:
          Snackbar.info(ERROR_MESSAGES.TOKEN_EXPIRE);
          return closeSessionAndSignInWithRedirect();
        default:
          setIsRenderPages(true);
          break;
      }
    };

    checkAuthAndTokenExpiration();
  }, [router.pathname]);

  return isRenderPages ? <>{children}</> : null;
}
