import { useRouter } from 'next/router';
import React from 'react';
import { useRecoilValue } from 'recoil';
import { userModelState } from 'recoil/atoms/userAtoms';
import { MainWidgetsTypeEnum, RightWidgetsTypeEnum } from '../../pages/DashboardPage/WidgetsType';
import ComponentPageHeader from './ComponentPageHeader';
import DefaultGlobalHeader from './DefaultGlobalHeader';
import LogoutHeader from './LogoutHeader';
import UnauthorizedHeader from './UnauthorizedHeader';
import { ROUTES_PATH_URLS } from 'components/routing/constants/routes';

const SiteHeader = () => {
  const userModel = useRecoilValue(userModelState);
  const {
    pathname,
    query: { name: widgetName },
  } = useRouter();

  type WidgetNameType = MainWidgetsTypeEnum | RightWidgetsTypeEnum;

  const getWidgetName = (name: WidgetNameType) => {
    switch (name) {
      case MainWidgetsTypeEnum.SCATTER_COST_OCCUPANCY_DASHBOARD:
        return 'Total Cost By Region';
      case RightWidgetsTypeEnum.PORTFOLIO_TABLE:
        return 'Portfolio Table';
      default:
        return '';
    }
  };

  const renderHeader = (_pathname: string) => {
    switch (_pathname) {
      case ROUTES_PATH_URLS.component:
        return (
          <ComponentPageHeader
            dashboardName={userModel?.selectedClientDashboardModel.dashboardName.toUpperCase()}
            widgetName={getWidgetName(widgetName as WidgetNameType)}
          />
        );
      case ROUTES_PATH_URLS.account_sign_in:
        return <LogoutHeader pageTitle={userModel?.pageTitle} />;
      default:
        return userModel?.isAuth ? (
          <DefaultGlobalHeader pageTitle={userModel?.pageTitle} />
        ) : (
          <UnauthorizedHeader pageTitle={userModel?.pageTitle} />
        );
    }
  };

  return renderHeader(pathname);
};

export default SiteHeader;
