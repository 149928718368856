import * as React from 'react';
import { SVGProps } from 'react';
const SvgAyBaseTableColor = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 8 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect width={8} height={16} rx={4} fill={props.color} />
  </svg>
);
export default SvgAyBaseTableColor;
