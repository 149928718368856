import format from 'date-fns/format';
import { parseISO } from 'date-fns';

export const getYearsMonths = (numMonths: number) => {
  const wholeYears = Math.floor(numMonths / 12);
  const remainingMonths = numMonths % 12;
  return `${wholeYears} years, ${remainingMonths} months`;
};

export const parseDate = (date: string, formatStr: string) => {
  let val;
  try {
    val = format(parseISO(date), formatStr);
    return val;
  } catch (error) {
    return date;
  }
};
