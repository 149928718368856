// Here we can all paths for our api

export const API_PATH = {
  client: {
    getClientsWithUserClients: `client/get-clients-with-user-clients`,
    createOne: `client/create-one/`,
    updateOne: `client/update-one/`,
    deleteOne: `client/delete-one/`,
  },
  clientDashboard: {
    getAllClientDashboards: 'client-dashboard/get-all-client-dashboards',
    createOne: `client-dashboard/create-one/`,
    updateOne: `client-dashboard/update-one/`,
    deleteOne: `client-dashboard/delete-one/`,
    createByDashboardIdAndClientUuid: `client-dashboard/create-by-dashboard-uuid-and-client-uuid`,
    updateClientDashboardImage: `client-dashboard/update-client-dashboard-image-url`,
  },
  clientDashboardBookmark: {
    createOne: `client-dashboard-bookmark/create-one`,
    deleteOne: `client-dashboard-bookmark/delete-one/`,
  },
  dashboard: {
    getAllDashboards: `dashboard/get-all-dashboards`,
    createOne: `dashboard/create-one/`,
  },
  snowflake: {
    vLeasesWithVPropertyByPropertyId: `portfolio-analytics/v-leases-with-v-property-by-property-id`,
    vLeases: 'portfolio-analytics/v-leases',
    vProperties: 'portfolio-analytics/v-properties',
    criticalDates: 'portfolio-analytics/critical-dates',
  },
  user: {
    getUsersWithUsersClients: 'user/get-users-with-clients/',
    createUserWithUserClients: 'user/create-users-with-clients/',
    updateUserWithUserClients: 'user/update-users-with-clients/',
    updateUserPreferences: 'user/update-user-preferences/',
    deleteOne: `user/delete-one/`,
    getUserInfo: `user/get-user-info`,
    getAdditionalInfoForUser: 'user/get-additional-info-for-user',
    updateUserActiveClient: 'user/update-user-active-client/',
  },
  documents: {
    getDocuments: `documents/get-documents`,
    deleteDocumentByTag: 'documents/delete-document-by-tag',
  },
  currency: {
    getCurrencies: 'currency/get-currencies',
  },
};
