/*
Author: Eli Elad Elrom
*/
import { Environment, Network, RecordSource, Store } from 'relay-runtime';

function fetchQuery(operation: { text: any }, variables: any) {
  const graphQLURL = process.env.NEXT_PUBLIC_AVANT_GRAPHQL_PROPERTIES;
  return fetch(graphQLURL ?? 'https://dev.avant.avisonyoung.com/api/properties/graphql', {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      /* 'Authorization': `Bearer ${process.env.NEXT_PUBLIC_AVANT_GRAPHQL_PROPERTIES_KEY as string}` */
    },
    body: JSON.stringify({
      query: operation.text,
      variables,
    }),
  }).then((response) => {
    return response.json();
  });
}

const network = Network.create(fetchQuery);
const source = new RecordSource();
const store = new Store(source);

export default new Environment({
  network,
  store,
});
