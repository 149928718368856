import { APP_CONFIG } from '../../../constants/config';

export const ROUTES_PATH_URLS = {
  something_went_wrong: (id: string) => `/something-went-wrong?id=${id}`,
  admin_unauthorized: '/admin/unauthorized',
  admin: '/admin',
  admin_users: '/admin/users',
  account_sign_in: '/account/signin',
  account_sign_out: '/account/signout',
  account_unauthorized: '/account/unauthorized',
  home: '/',
  preferences: '/preferences',
  okta_callback: `${APP_CONFIG.basePath}/okta/callback`,
  okta_offline_callback: `/okta/callback`,
  component: '/component',
  property_profile: '/property-profile',
  property_profile_with_id: (propertyId: string) =>
    `${APP_CONFIG.basePath}${ROUTES_PATH_URLS.property_profile}/${propertyId}`,
  critical_date: '/criticaldate',
};
