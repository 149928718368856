const logger = (...args: any[]): void => {
  if (
    process.env.NEXT_PUBLIC_ENVIRONMENT === `local` ||
    process.env.NEXT_PUBLIC_ENVIRONMENT === 'local_skip_okta'
  ) {
    // eslint-disable-next-line no-console
    console.log(...args);
  }
};

export default logger;
