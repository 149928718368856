import {
  PermissionStatusEnum,
  PermissionStatusType,
} from 'components/routing/enums/userClientPermissionEnum';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { currentUserClientPermissionState, userModelState } from 'recoil/atoms/userAtoms';
import { parsePermissionString } from 'components/routing/utils/permissionPathHelper';
import { INIT_USER_CLIENT_PERMISSIONS } from 'components/routing/constants/permissions';
import { IUserClientPermission } from 'models';

export const checkUserClientPermission = (
  userClientPermissions: IUserClientPermission[] = []
): string => {
  const permissionStrings = userClientPermissions.map(({ name, status }) => `${name}:${status}`);
  return permissionStrings.join('|');
};

export function useCheckUserClientsPermission(permissionName: string): boolean {
  const userModel = useRecoilValue(userModelState);
  const setUserClientCurrentPermissionStatus = useSetRecoilState(currentUserClientPermissionState);
  const pagesToCheckPermissionFor = INIT_USER_CLIENT_PERMISSIONS.map((i) => i.name.toLowerCase());

  const selectedUserClientModel = userModel.selectedUserClientModel;

  const isAllowedToViewOrEdit = (permissionStatus: PermissionStatusType): boolean =>
    permissionStatus.toLowerCase() !== PermissionStatusEnum.NONE.toLowerCase();

  const isPermissionListNotIncludedInList = !pagesToCheckPermissionFor.includes(permissionName);

  if (!selectedUserClientModel || isPermissionListNotIncludedInList) {
    return true;
  }

  const generatedUserClientPermissions = parsePermissionString(
    selectedUserClientModel.userClientUuid,
    selectedUserClientModel.datumPermission
  );

  const currentUserClientPermission = generatedUserClientPermissions.find((ucp) => {
    const checkUserPermissionName = ucp.name.toLowerCase();
    return checkUserPermissionName === permissionName;
  });

  if (currentUserClientPermission && isAllowedToViewOrEdit(currentUserClientPermission.status)) {
    setUserClientCurrentPermissionStatus(currentUserClientPermission.status);
    return true;
  }

  return false;
}
