/*
Author: Zachary Katsnelson
*/

import { Typography } from '@mui/material';
import styled from 'styled-components';
import { SITE_HEADER_HEIGHT } from 'utils/layoutHelper';

export const WrapperOpen = styled.div`
  background-color: ${(props) => props.theme.colors.white};
  border: 1px solid ${(props) => props.theme.colors.cloudGrey};
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px rgba(0, 0, 0, 0.14),
    0px 1px 3px rgba(0, 0, 0, 0.12);
  border-radius: 0.4rem;
  position: absolute;
  width: 30rem;
  height: 14rem;
  top: ${SITE_HEADER_HEIGHT};
  margin-top: -0.8rem;
  right: 12.4rem;
  padding: 0.6rem;
  text-align: left;
  z-index: 999;
  transition: all 0.2s ease-out;
`;

export const InnerWrapper = styled.div`
  padding-left: 0.5rem;
`;

export const ModuleName = styled(Typography)`
  font-weight: 600;
`;
ModuleName.defaultProps = {
  variant: 'body1',
};

export const ModuleDescription = styled(Typography)``;
ModuleDescription.defaultProps = {
  variant: 'extraSmallParagraph',
};

export const ModuleLogo = styled.div`
  display: flex;
  align-items: center;
  background-color: ${(props) => props.theme.colors.backgroundGrey};
  padding: 1rem 1rem 1rem 1rem;
  border-radius: 0.5rem;
  margin-right: 1rem;
`;

export const Row = styled.div`
  display: flex;
  padding: 0.5rem 1rem 0.5rem 1rem;
  margin-bottom: 1rem;
  border-radius: 0.5rem;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  :hover {
    background-color: ${(props) => props.theme.colors.backgroundGrey};
  }
`;
