import { atom, selector } from 'recoil';
import { ClientModel } from '../../models';
import { AdditionalUserInfoResponse, AdminUser } from '../../types/users';
import { getDashboardsRequest } from '../selectors/dashboardSelectors';
import { getAdditionalInfoForUsersRequest } from '../selectors/usersSelectors';

export const adminUsersState = atom<AdminUser[] | []>({
  key: 'AdminUsersState',
  default: [],
});

export const adminClientsState = atom<ClientModel[]>({
  key: 'AdminClientsState',
  default: [],
});

export const dashboardsState = atom({
  key: 'DashboardsState',
  default: selector({
    key: 'DashboardsStateLoader',
    get: () => {
      return getDashboardsRequest();
    },
  }),
});

export const additionalUserInfoState = atom({
  key: 'AdditionalUserInfoState',
  default: selector({
    key: 'AdditionalUserInfoStateResponseStateLoader',
    get: (): Promise<AdditionalUserInfoResponse> => {
      return getAdditionalInfoForUsersRequest();
    },
  }),
});
