import { regex } from '../constants/regex';
import { differenceInDays, differenceInMonths } from 'date-fns';

const numberIsNotValid = '-';
const isValidNumberFormatter = (number: string | number): boolean => {
  return !isNaN(parseInt(String(number)));
};

export const trimStringWithSpaces = (str: string): string => {
  const strSplit = str.split('|');
  const text: string = strSplit[0];
  const maxLength: number = parseInt(strSplit[1]);
  if (text.length <= maxLength) {
    return text;
  }

  const trimmedText = text.slice(0, maxLength).trim();
  return `${trimmedText}...`;
};

export const capitalizeEachWord = (str: string): string => {
  return str
    .toLowerCase()
    .split(' ')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
};

export const convertFloatToNumberWithCommasWithNoDecimal = (num: number): string => {
  if (!isValidNumberFormatter(num)) return numberIsNotValid;
  return num.toFixed(0).toString().replace(regex.numberWithCommas, ',');
};

export const convertFloatToPercentage = (num: string | number): string => {
  if (!isValidNumberFormatter(num)) return numberIsNotValid;
  return (Number(num) * 100).toFixed(2) + '%';
};

export const floatWithNoDecimal = (num: string): string | number => {
  if (!isValidNumberFormatter(num)) return numberIsNotValid;
  return Math.round(num as unknown as number);
};

export const floatOneDecimalWithPercentage = (num: string | number): string => {
  if (!isValidNumberFormatter(num)) return numberIsNotValid;
  const parsedNum = typeof num === 'string' ? parseFloat(num) : num;
  return parsedNum.toFixed(1) + '%';
};

export const floatOneDecimal = (num: string): string => {
  if (!isValidNumberFormatter(num)) return numberIsNotValid;
  return (num as unknown as number).toFixed(1);
};

export const floatFourDecimalWithPercentage = (num: string): string => {
  if (!isValidNumberFormatter(num)) return numberIsNotValid;
  return (num as unknown as number).toFixed(4) + '%';
};

export const addPercentage = (value: string | number): string => value + '%';

export const integerWithCommas = (num: string): string => {
  if (!isValidNumberFormatter(num)) return numberIsNotValid;
  return parseInt(num).toString().replace(regex.numberWithCommas, ',');
};

export const numberWithCommas = (num: string): string => {
  if (!isValidNumberFormatter(num)) return numberIsNotValid;
  return num.toString().replace(regex.numberWithCommas, ',');
};

export const currencySymbolWithCommas = (num: string, currencySymbol: string): string => {
  if (!isValidNumberFormatter(num)) return numberIsNotValid;
  if (parseFloat(num) > 0) {
    return `${currencySymbol}${parseFloat(num).toFixed(2).toLocaleString()}`;
  } else {
    return `-${currencySymbol}${(parseFloat(num) * -1).toFixed(2).toLocaleString()}`;
  }
};

export const addCurrencySymbolWithCommasBasedOnUserCurrency = (
  num: string,
  currencySymbol: string
): string => {
  if (!isValidNumberFormatter(num)) {
    return numberIsNotValid;
  }
  const formattedNum = parseInt(num, 10).toLocaleString();
  return parseInt(num, 10) >= 0 ? `${currencySymbol}${formattedNum}` : `-${formattedNum}`;
};

export const addCurrencySymbolWithCommasAndScale = (num: string, currencySymbol: string) => {
  if (!isValidNumberFormatter(num)) return numberIsNotValid;
  const parsedNum = parseInt(num, 10);
  if (parsedNum >= 1000000000) {
    return `${currencySymbol}${Math.floor(parsedNum / 1000000000).toLocaleString()} M`;
  } else if (parsedNum >= 1000000) {
    return `${currencySymbol}${Math.floor(parsedNum / 1000).toLocaleString()} K`;
  } else {
    return `${currencySymbol}${parsedNum.toLocaleString()}`;
  }
};

export const addCurrencySymbolWithTwoDecimal = (num: string, currencySymbol: string): string => {
  if (!isValidNumberFormatter(num)) return numberIsNotValid;
  return `${currencySymbol}${Number(num as unknown as number).toFixed(2)}`;
};

export const addCurrencySymbolSign = (num: number, currencySymbol: string): string => {
  if (!isValidNumberFormatter(num)) {
    return numberIsNotValid;
  }
  return `${currencySymbol}${Number(num).toFixed(2)}`;
};

export const sqfNumberWithUpperCase = (num: string): string => {
  if (!isValidNumberFormatter(num)) return numberIsNotValid;
  return `${parseInt(num, 10).toLocaleString()} SQF`;
};

export const sqfNumberWithCommas = (num: string): string => {
  if (!isValidNumberFormatter(num)) return numberIsNotValid;
  return `${parseInt(num, 10).toLocaleString()}`;
};

export const formatDateRemainYearMonth = (dateString: string): string => {
  const date = new Date(dateString);
  const today = new Date();
  const monthsSinceDate = differenceInMonths(today, date);
  const daysSinceDate = differenceInDays(today, date);
  if (monthsSinceDate < 0) {
    return 'Expired';
  }
  const numYears = Math.floor(monthsSinceDate / 12);
  const numMonths = monthsSinceDate - numYears * 12;
  if (!numYears && !numMonths) {
    return `${daysSinceDate} Days`;
  }
  if (!numYears) {
    return `${numMonths} Mos.`;
  }
  return `${numYears} Yr, ${numMonths} Mos.`;
};
