import { IErrorType } from 'interfaces';
import { FailureCodes } from 'components/pages/SomethingWentWrongPage/constants';
import Snackbar from '../../src/components/shared/Toaster/ToasterWithoutState';
import Router from 'next/router';
import { ROUTES_PATH_URLS } from 'components/routing/constants/routes';

const FETCH_ERROR_MESSAGE = 'Failed to fetch';
const FILE_NOT_FOUND_ERROR_MESSAGE = 'no such file or directory';
const ENDPOINT_NOT_EXIST_ERROR_MESSAGE = 'This endpoint url does not exist';

const handleAuthErrors = (err: IErrorType) => {
  if (err.message === 'Token Expire') {
    return Router.push(ROUTES_PATH_URLS.account_sign_in);
  }
  return Router.push(ROUTES_PATH_URLS.something_went_wrong(FailureCodes.OKTA_SDK_AUTH_FAILED));
};

export const handleErrors = (err: IErrorType) => {
  if (err.message === FETCH_ERROR_MESSAGE) {
    Router.push(ROUTES_PATH_URLS.something_went_wrong(FailureCodes.SERVER_NOT_RESPOND));
  }

  if (err.message.includes(FILE_NOT_FOUND_ERROR_MESSAGE)) {
    return Snackbar.error(ENDPOINT_NOT_EXIST_ERROR_MESSAGE);
  }

  if (err.code === 401) {
    return handleAuthErrors(err);
  }

  const errorMessage = Array.isArray(err.message) ? JSON.stringify(err.message) : err.message;
  Snackbar.error(errorMessage);
};
