/*
Author: Eli Elad Elrom
File: clientDashboardSelectors.ts
*/

import { v4 as uuid } from 'uuid';
import { selector } from 'recoil';
import { setRecoil } from 'recoil-nexus';
import Snackbar from '../../components/shared/Toaster/ToasterWithoutState';
import { HttpMethod } from 'enums/httpMethodEnum';
import { IDeletedResponse } from 'interfaces';
import { ClientDashboardModel, DashboardModel } from 'models';
import { CreateClientDashboardDto, UpdateClientDashboardDto } from 'types/client-dashboard';
import { dashboardModelState } from '../atoms/dashboardsAtoms';
import { userModelState } from '../atoms/userAtoms';
import { getURLByEndpoint } from './SelectorsHelper';
import { API_PATH } from 'components/routing/constants/api';
import { request } from '../../../pages/api/fetchService';

export const CLIENT_DASHBOARD_SUCCESS_MESSAGES = {
  CLIENT_SWITCHED: 'Client switched successfully',
};

const SUCCESS_MESSAGES = {
  CREATED: 'Client Dashboard successfully created',
  UPDATED: 'Client Dashboard successfully updated',
  DELETED: 'Client Dashboard successfully deleted',
  ADD_FROM_LIBRARY: (libraryName: string) =>
    `Client Dashboard ${libraryName} successfully added from library`,
};

export const getClientDashboardsDataRequest = (clientUuid: string) => {
  if (!clientUuid) return [];
  return request<ClientDashboardModel[]>(
    getURLByEndpoint(API_PATH.clientDashboard.getAllClientDashboards, `?clientUuid=${clientUuid}`),
    HttpMethod.GET
  );
};

export const createClientDashboardRequest = async (
  dto: CreateClientDashboardDto
): Promise<ClientDashboardModel> => {
  const res = await request<ClientDashboardModel>(
    getURLByEndpoint(API_PATH.clientDashboard.createOne),
    HttpMethod.POST,
    dto
  );
  Snackbar.success(SUCCESS_MESSAGES.CREATED);
  return res;
};

export const updateClientDashboardRequest = async (
  clientDashboardUuid: string,
  dto: UpdateClientDashboardDto
): Promise<ClientDashboardModel> => {
  const res = await request<ClientDashboardModel>(
    getURLByEndpoint(API_PATH.clientDashboard.updateOne, clientDashboardUuid),
    HttpMethod.PATCH,
    dto
  );
  Snackbar.success(SUCCESS_MESSAGES.UPDATED);
  return res;
};

export const deleteClientDashboardRequest = async (
  clientDashboardUuid: string
): Promise<IDeletedResponse<ClientDashboardModel>> => {
  const res = await request<IDeletedResponse<ClientDashboardModel>>(
    getURLByEndpoint(API_PATH.clientDashboard.deleteOne, clientDashboardUuid),
    HttpMethod.DELETE
  );
  Snackbar.success(SUCCESS_MESSAGES.DELETED);
  return res;
};

export const addClientDashboardFromLibraryRequest = async (
  dashboardUuid: string,
  clientUuid: string
): Promise<ClientDashboardModel> => {
  const res = await request<ClientDashboardModel>(
    getURLByEndpoint(
      API_PATH.clientDashboard.createByDashboardIdAndClientUuid,
      `?dashboardUuid=${dashboardUuid}&clientUuid=${clientUuid}`
    ),
    HttpMethod.POST
  );
  Snackbar.success(SUCCESS_MESSAGES.ADD_FROM_LIBRARY(res.dashboardName));
  return res;
};

const updateClientDashboardImageRequest = (
  clientDashboardUuid: string,
  dashboardImage: string | null
): Promise<ClientDashboardModel> => {
  return request<ClientDashboardModel>(
    getURLByEndpoint(
      API_PATH.clientDashboard.updateClientDashboardImage,
      `?clientDashboardUuid=${clientDashboardUuid}&dashboardImage=${dashboardImage}`
    ),
    HttpMethod.PUT
  );
};

export const createClientDashboardSelector = selector({
  key: 'CreateClientDashboardSelector',
  get: ({ getCallback, get }) => {
    const dashboardModel = get(dashboardModelState);

    return getCallback(() => async (dto: CreateClientDashboardDto) => {
      setRecoil(userModelState, (prevUserModel) => ({
        ...prevUserModel,
        clientDashboardModels: [...prevUserModel.clientDashboardModels, { ...dto, dashboardModel }],
      }));
      setRecoil(dashboardModelState, undefined);

      await createClientDashboardRequest(dto);
    });
  },
});

export const updateClientDashboardSelector = selector({
  key: 'UpdateClientDashboardSelector',
  get: ({ getCallback }) => {
    return getCallback(() => async (dto: UpdateClientDashboardDto) => {
      setRecoil(userModelState, (prevUserModel) => ({
        ...prevUserModel,
        clientDashboardModels: prevUserModel.clientDashboardModels.map((cd: ClientDashboardModel) =>
          cd.clientDashboardUuid !== dto.clientDashboardUuid ? cd : dto
        ),
      }));

      await updateClientDashboardRequest(dto.clientDashboardUuid, dto);
    });
  },
});

export const deleteClientDashboardSelector = selector({
  key: 'DeleteClientDashboardSelector',
  get: ({ getCallback }) => {
    return getCallback(() => async (clientDashboardUuid: string) => {
      setRecoil(userModelState, (prevUserModel) => ({
        ...prevUserModel,
        clientDashboardModels: prevUserModel.clientDashboardModels.filter(
          (i) => i.clientDashboardUuid !== clientDashboardUuid
        ),
      }));

      await deleteClientDashboardRequest(clientDashboardUuid);
    });
  },
});

export const addClientDashboardFromLibrarySelector = selector({
  key: 'AddClientDashboardFromLibrarySelector',
  get: ({ getCallback, get }) => {
    const userModel = get(userModelState);
    return getCallback(() => async (dashboardModel: DashboardModel, clientUuid: string) => {
      const currId = uuid();
      const newClientDashboardModels = [
        ...userModel.clientDashboardModels,
        {
          ...dashboardModel,
          clientUuid,
          clientDashboardUuid: currId,
        },
      ];

      const updatedDashRes = await addClientDashboardFromLibraryRequest(
        dashboardModel.dashboardUuid,
        clientUuid
      );

      const updatedClientDashboardModels = newClientDashboardModels.map(
        (cd: ClientDashboardModel) => {
          if (cd.clientDashboardUuid === currId) {
            return updatedDashRes;
          }
          return cd;
        }
      );

      setRecoil(userModelState, (prevUserModel) => ({
        ...prevUserModel,
        clientDashboardModels: updatedClientDashboardModels,
      }));
    });
  },
});

export const updateClientDashboardImageSelector = selector({
  key: 'UpdateClientDashboardImageURL',
  get: ({ getCallback }) => {
    return getCallback(
      () =>
        (
          clientDashboardUuid: string,
          dashboardImage: string | null
        ): Promise<ClientDashboardModel> => {
          return updateClientDashboardImageRequest(clientDashboardUuid, dashboardImage);
        }
    );
  },
});

export const getClientDashboardsBasedOnClientSelector = selector({
  key: 'GetClientDashboardsBasedOnClientSelector',
  get: async ({ get }) => {
    const userModel = get(userModelState);
    return getClientDashboardsDataRequest(
      userModel.selectedUserClientModel?.clientModel.clientUuid!
    );
  },
});
