import { ClientModel, INIT_USER, UserClientModel, UserModel } from 'models';
import Router from 'next/router';
import { selector } from 'recoil';
import { setRecoil } from 'recoil-nexus';
import {
  AdditionalUserInfoResponse,
  AdminUser,
  CreateUserWithUserClientsDto,
  SaveUserPreferencesDto,
  UpdateUserWithUserClientsDto,
  UserTokenResponse,
} from 'types/users';
import { IPreferencesFormValues } from 'components/pages/PreferencesPage/form/PreferencesForm';
import { FailureCodes } from 'components/pages/SomethingWentWrongPage/constants';
import Snackbar from '../../components/shared/Toaster/ToasterWithoutState';
import { HttpMethod } from 'enums/httpMethodEnum';
import { IDeletedResponse, IErrorType } from 'interfaces';
import { adminUsersState } from '../atoms/adminAtoms';
import { userModelState } from '../atoms/userAtoms';
import { getClientDashboardsDataRequest } from './clientDashboardSelectors';
import { getURLByEndpoint } from './SelectorsHelper';
import { leasesState, propertiesState } from '../atoms/dashboardsAtoms';
import { API_PATH } from 'components/routing/constants/api';
import { request } from '../../../pages/api/fetchService';
import { ROUTES_PATH_URLS } from 'components/routing/constants/routes';
import { formatClientDashboardsWithBookmarks } from 'utils/cliendDashboardHelper';
import { getUserClientByActiveClientUuid } from 'utils/userHelper';

export const USER_SUCCESS_MESSAGES = {
  CREATED: 'User successfully created',
  UPDATED: 'User successfully updated',
  DELETED: 'User successfully deleted',
  PREFERENCES_UPDATES: 'User preferences was updated successfully',
};

export const USER_ERROR_MESSAGES = {
  UNSUCCESSFUL_SIGN_IN: 'Unsuccessful sign in attempt',
  FORBID_FOR_NONE_ADMIN: 'You must be admin to have access to this page',
};

export function getAdditionalInfoForUsersRequest(): Promise<AdditionalUserInfoResponse> {
  return request<AdditionalUserInfoResponse>(
    getURLByEndpoint(API_PATH.user.getAdditionalInfoForUser),
    HttpMethod.GET
  );
}

export function getAdminUsersRequest(): Promise<AdminUser[]> {
  return request<AdminUser[]>(
    getURLByEndpoint(API_PATH.user.getUsersWithUsersClients),
    HttpMethod.GET
  );
}

export const getUserRequest = async (token: string): Promise<UserTokenResponse | undefined> => {
  try {
    return await request<UserTokenResponse>(
      getURLByEndpoint(API_PATH.user.getUserInfo),
      HttpMethod.GET,
      undefined,
      token
    );
  } catch (e) {
    const err = e as IErrorType;
    if (err?.code === 404) {
      await Router.push(ROUTES_PATH_URLS.something_went_wrong(FailureCodes.USER_NOT_EXIST));
    }
  }
};

export const createUserWithUsersClientsRequest = async (
  dto: CreateUserWithUserClientsDto
): Promise<AdminUser> => {
  const res = await request<AdminUser>(
    getURLByEndpoint(API_PATH.user.createUserWithUserClients),
    HttpMethod.POST,
    dto
  );
  Snackbar.success(USER_SUCCESS_MESSAGES.CREATED);
  return res;
};

export const updateUserWithUserClientsRequest = async (
  dto: UpdateUserWithUserClientsDto
): Promise<AdminUser> => {
  const res = await request<AdminUser>(
    getURLByEndpoint(API_PATH.user.updateUserWithUserClients),
    HttpMethod.PATCH,
    dto
  );
  Snackbar.success(USER_SUCCESS_MESSAGES.UPDATED);
  return res;
};

export const updateUserActiveClientRequest = async (
  userUuid: string,
  activeClientUuid: string
): Promise<ClientModel | undefined> => {
  if (!activeClientUuid) return;
  return request<ClientModel>(
    getURLByEndpoint(API_PATH.user.updateUserActiveClient, userUuid, 'update.json'),
    HttpMethod.PATCH,
    {
      activeClientUuid,
    }
  );
};

export const deleteUserWithUserClientsRequest = async (
  userUuid: string
): Promise<IDeletedResponse<UserModel>> => {
  const res = await request<IDeletedResponse<UserModel>>(
    getURLByEndpoint(API_PATH.user.deleteOne, `${userUuid}`),
    HttpMethod.DELETE
  );
  Snackbar.success(USER_SUCCESS_MESSAGES.DELETED);
  return res;
};

export const saveUserPreferencesRequest = async (dto: SaveUserPreferencesDto, userUuid: string) => {
  const res = await request(
    getURLByEndpoint(API_PATH.user.updateUserPreferences, userUuid),
    HttpMethod.PATCH,
    dto
  );
  Snackbar.success(USER_SUCCESS_MESSAGES.PREFERENCES_UPDATES);
  return res;
};

export const resetPropertiesAndLeasesState = () => {
  setRecoil(propertiesState, undefined);
  setRecoil(leasesState, undefined);
};

export const createUserWithUserClientsSelector = selector({
  key: 'CreateUserWithUserClientsSelector',
  get: ({ getCallback }) => {
    return getCallback(() => async (dto: CreateUserWithUserClientsDto) => {
      const newUserWithClientsRes = await createUserWithUsersClientsRequest(dto);
      setRecoil(adminUsersState, (prevAdminUsers) => [...prevAdminUsers, newUserWithClientsRes]);
      return newUserWithClientsRes;
    });
  },
});

export const updateUserWithUserClientsSelector = selector({
  key: 'UpdateUserWithUserClientsSelector',
  get: ({ getCallback, get }) => {
    const userModel = get(userModelState);

    const updateCurrentUser = async (updatedAdminUser: AdminUser) => {
      const { userUuid, userClientModels } = updatedAdminUser;

      if (userModel.userUuid === userUuid) {
        if (userModel.userUuid === userUuid) {
          const firstUserClientModel = userClientModels[0];

          const currentUserClient =
            getUserClientByActiveClientUuid(userClientModels, userModel.activeClientUuid) ??
            firstUserClientModel;

          const activeClientUuid = currentUserClient?.clientModel?.clientUuid;
          const clientDashboardModels = await getClientDashboardsDataRequest(activeClientUuid);

          const updatedUserModel: UserModel = {
            ...userModel,
            ...updatedAdminUser,
            clientDashboardModels: formatClientDashboardsWithBookmarks(
              clientDashboardModels,
              userModel.clientDashboardBookmarkModels
            ),
            selectedUserClientModel: currentUserClient,
            activeClientUuid,
          };

          updateUserActiveClientRequest(userModel.userUuid, activeClientUuid);
          resetPropertiesAndLeasesState();
          setRecoil(userModelState, updatedUserModel);
        }
      }
    };

    const updateAdminUsers = (updatedAdminUser: AdminUser) => {
      setRecoil(adminUsersState, (prevAdminUsers) =>
        prevAdminUsers.map((pau) =>
          pau.userUuid === updatedAdminUser.userUuid ? updatedAdminUser : pau
        )
      );
    };

    return getCallback(() => async (dto: UpdateUserWithUserClientsDto) => {
      const adminUser = await updateUserWithUserClientsRequest(dto);

      await updateCurrentUser(adminUser);

      updateAdminUsers(adminUser);
      resetPropertiesAndLeasesState();

      return adminUser;
    });
  },
});

export const deleteUserWithUserClientsSelector = selector({
  key: 'DeleteUserWithUserClientsSelector',
  get: ({ getCallback, get }) => {
    const userModel = get(userModelState);

    return getCallback(() => async (currAdminUser: AdminUser) => {
      const deletedItem = await deleteUserWithUserClientsRequest(currAdminUser.userUuid);

      if (userModel.userUuid === currAdminUser.userUuid) {
        await Router.push(ROUTES_PATH_URLS.something_went_wrong(FailureCodes.USER_NOT_EXIST));
        setRecoil(userModelState, INIT_USER);
      }

      setRecoil(adminUsersState, (prevAdminUsers) =>
        prevAdminUsers.filter((i: AdminUser) => i.userUuid !== deletedItem.deletedEntity.userUuid)
      );
    });
  },
});

export const saveUserPreferencesSelector = selector({
  key: 'SaveUserPreferencesSelector',
  get: ({ getCallback, get }) => {
    const userModel = get(userModelState);

    const updateCurrentUser = (
      newUserModel: Partial<UserModel>,
      newUserClientModel: Partial<UserClientModel>
    ) => {
      setRecoil(userModelState, (prevUserModel) => ({
        ...prevUserModel,
        ...newUserModel,
        userClientModels: prevUserModel.userClientModels.map((uc) =>
          uc.userClientUuid === prevUserModel.selectedUserClientModel?.userClientUuid
            ? { ...uc, ...newUserClientModel }
            : uc
        ),
        selectedUserClientModel: {
          ...prevUserModel.selectedUserClientModel!,
          ...newUserClientModel,
        },
      }));
    };

    return getCallback(() => async (data: IPreferencesFormValues) => {
      const {
        userInitial,
        firstName,
        lastName,
        userName,
        email,
        oktaUserId,
        unitMeas,
        currencyCode,
        formatDate,
        formatNumber,
        timezone,
        userLanguage,
      } = data;

      const newUserModel = {
        userInitial,
        firstName,
        lastName,
        userName,
        email,
        oktaUserId,
      };

      const newUserClientModel = {
        unitMeas,
        currencyCode,
        formatDate,
        formatNumber,
        userLanguage,
        timezone,
      };

      await saveUserPreferencesRequest(
        {
          userModel: newUserModel,
          userClientModel: {
            ...newUserClientModel,
            userClientUuid: userModel.selectedUserClientModel!.userClientUuid,
          },
        },
        userModel.userUuid
      );

      updateCurrentUser(newUserModel, newUserClientModel);
    });
  },
});

export const getAdminUsersSelector = selector({
  key: 'GetAdminUsersSelector',
  get: async (): Promise<AdminUser[]> => {
    const res = await getAdminUsersRequest();
    setRecoil(adminUsersState, res);
    return res;
  },
});
