import { Divider, Left, LogoContainer, LogoText, Wrapper } from './SiteHeader.css';
import React from 'react';
import { AYLogo } from '../../shared/AYLogo';
import { useRouter } from 'next/router';
import { ROUTES_PATH_URLS } from 'components/routing/constants/routes';

const UnauthorizedHeader = ({ pageTitle }: UnauthorizedHeaderProps) => {
  const router = useRouter();
  return (
    <Wrapper>
      <Left>
        <LogoContainer onClick={() => router.push(ROUTES_PATH_URLS.home)}>
          <AYLogo />
        </LogoContainer>
        <Divider />
        <LogoText>{pageTitle}</LogoText>
      </Left>
    </Wrapper>
  );
};

export interface UnauthorizedHeaderProps {
  pageTitle: string;
}

export default UnauthorizedHeader;
