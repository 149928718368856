import {
  Wrapper,
  Left,
  Right,
  ComponentPageHeaderContent,
  ClientLogoContainer,
  PrintHeaderIconButton,
  LogoContainer,
  Divider,
  LogoText,
} from './SiteHeader.css';
import Typography from '@mui/material/Typography';
import HeaderClientLogo from './HeaderClientLogo';
import React from 'react';
import Pdf from 'react-to-pdf';
import { useRecoilState } from 'recoil';
import { printState } from '../../../recoil/atoms/printAtoms';
import { downloadPng, getFileName } from '../../../utils/printHelper';
import HeaderIconButton from '../../shared/HeaderIconButton';
import { PrintOutlined, ImageOutlined, PictureAsPdfOutlined } from '@mui/icons-material';
import { FormatterType, getFormatter } from '../../../utils/formatters';
import { AYLogo } from '../../../components/shared/AYLogo';

const ComponentPageHeader = ({ dashboardName, widgetName }: ComponentPageHeaderProps) => {
  const [ref] = useRecoilState(printState);

  const date = getFormatter(FormatterType.FORMAT_DATE_DAY_MONTH_YEAR)(
    new Date().toISOString()
  ).toUpperCase();

  return (
    <Wrapper>
      <Left>
        <LogoContainer>
          <AYLogo />
        </LogoContainer>
        <Divider />
        <LogoText>{getFormatter(FormatterType.CAPITALIZE_EACH_WORD)(dashboardName)}</LogoText>
      </Left>
      <Right>
        <PrintHeaderIconButton icon={<PrintOutlined />} onClick={() => window.print()} />
        <HeaderIconButton
          icon={<ImageOutlined />}
          onClick={() => downloadPng(ref.printRef, widgetName)}
        />
        <Pdf
          targetRef={ref.printRef}
          filename={`${getFileName('pdf', widgetName)}`}
          x={10}
          y={10}
          scale={0.5}
        >
          {/* @ts-ignore */}
          {({ toPdf }) => <HeaderIconButton icon={<PictureAsPdfOutlined />} onClick={toPdf} />}
        </Pdf>
        <ComponentPageHeaderContent>
          <ClientLogoContainer>
            <HeaderClientLogo />
          </ClientLogoContainer>
          <Typography variant="componentHeaderDate">{date}</Typography>
        </ComponentPageHeaderContent>
      </Right>
    </Wrapper>
  );
};

export interface ComponentPageHeaderProps {
  widgetName: string;
  dashboardName: string;
}

export default ComponentPageHeader;
