/*
Author: Eli Elad Elrom
*/

import { css } from 'styled-components';
import { geoSlabFont, openSansFont } from '../styles/fonts';

/* Slab */
export const logoText = css`
  font-family: ${geoSlabFont.style.fontFamily};
  font-size: 2rem;
  font-weight: 600;
  line-height: 2.4rem;
  letter-spacing: 0em;
  text-align: left;
`;

/* OpenSans */
export const typeStyleSlabText1 = css`
  font-weight: 500;
  font-size: 5rem;
  line-height: 10.8rem;
`;

export const typeStyleSlabText2 = css`
  font-weight: 400;
  font-size: 4rem;
  line-height: 10.8rem;
`;

export const typeStyleSlabText3 = css`
  font-weight: 300;
  font-size: 3rem;
  line-height: 10.8rem;
`;

export const typeStyleSlabText4 = css`
  font-weight: 200;
  font-size: 2rem;
  line-height: 10.8rem;
`;

export const typeStyleSlabText5 = css`
  font-weight: 400;
  font-size: 3rem;
  line-height: 4.5rem;
  letter-spacing: 0.0333333rem;
`;

export const typeStyleSlabText6 = css`
  font-weight: 600;
  font-size: 2.4rem;
  line-height: 3.6rem;
  letter-spacing: 0.025rem;
`;

export const typeStyleSlabText7 = css`
  font-weight: 400;
  font-size: 2.4rem;
  line-height: 2.8rem;
  letter-spacing: 0.025rem;
`;

export const typeStyleSlabText8 = css`
  font-weight: 200;
  font-size: 1.5rem;
  line-height: 0.8rem;
`;

export const typeStyleOpenSansText1 = css`
  font-weight: 500;
  font-size: 5rem;
  line-height: 10.8rem;
`;

export const typeStyleOpenSansText2 = css`
  font-weight: 400;
  font-size: 4rem;
  line-height: 10.8rem;
`;

export const typeStyleOpenSansText3 = css`
  font-weight: 300;
  font-size: 3rem;
  line-height: 10.8rem;
`;

export const typeStyleOpenSansText4 = css`
  font-weight: 200;
  font-size: 2rem;
  line-height: 10.8rem;
`;

export const typeStyleOpenSansText5 = css`
  font-weight: 400;
  font-size: 3rem;
  line-height: 4.5rem;
  letter-spacing: 0.0333333rem;
`;

export const typeStyleOpenSansText6 = css`
  font-weight: 600;
  font-size: 2.4rem;
  line-height: 3.6rem;
  letter-spacing: 0.025rem;
`;

export const typeStyleOpenSansText7 = css`
  font-weight: 200;
  font-size: 1rem;
  line-height: 0.8rem;
`;

export const typeStyleOpenSansText8 = css`
  font-weight: 400;
  font-size: 1.3rem;
  line-height: 1rem;
`;

export const typeStyleOpenSansText9 = css`
  font-weight: 400;
  font-size: 1.4rem;
  line-height: 2rem;
`;

export const typeStyleOpenSansText10 = css`
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.4rem;
`;

export const typeStyleOpenSansText11 = css`
  font-weight: 400;
  font-size: 1.4rem;
  line-height: 2.4rem;
`;

/* Button Start */

export const typeStyleButtonLarge = css`
  font-weight: 700;
  font-size: 2.4rem;
  line-height: 3.3rem;
  letter-spacing: 0.025rem;
`;

export const typeStyleButtonSmall = css`
  font-weight: 400;
  font-size: 2rem;
  line-height: 2.4rem;
  letter-spacing: 0.025rem;
`;

export const typeStyleButtonWide = css`
  font-weight: 700;
  font-size: 2rem;
  line-height: 2.7rem;
  letter-spacing: 0.025rem;
`;

export const typeStyleButtonHover = css`
  font-weight: 700;
  font-size: 2.8rem;
  line-height: 3.8rem;
  letter-spacing: 0.025rem;
`;

export const typeStyleButtonForm = css`
  font-weight: 500;
  font-size: 1.4rem;
  line-height: 1.7rem;
`;

/* Button End */

// brand typography below

export const heading1 = css`
  //styleName: Heading/H1;

  font-size: 4.8rem;
  font-weight: 700;
  line-height: 4.8rem;
  letter-spacing: -0.02em;
  text-align: left;
`;

export const heading2 = css`
  //styleName: Heading/H2;

  font-size: 4rem;
  font-weight: 700;
  line-height: 4.4rem;
  letter-spacing: -0.02em;
  text-align: left;
`;

export const heading3 = css`
  //styleName: Heading/H3;

  font-size: 3.2rem;
  font-weight: 700;
  line-height: 3.6rem;
  letter-spacing: 0em;
  text-align: left;
`;

export const heading4 = css`
  //styleName: Heading/H4;

  font-size: 2.4rem;
  font-weight: 700;
  line-height: 2.8rem;
  letter-spacing: 0em;
  text-align: left;
`;

export const heading5 = css`
  //styleName: Heading/H5;

  font-size: 2rem;
  font-weight: 700;
  line-height: 2.4rem;
  letter-spacing: 0em;
  text-align: left;
`;

export const heading6 = css`
  //styleName: Heading/H6;

  font-size: 1.6rem;
  font-weight: 700;
  line-height: 2.4rem;
  letter-spacing: 0em;
  text-align: left;
`;

export const boldedParagraph = css`
  font-size: 2rem;
  font-weight: 700;
  line-height: 2.4rem;
  letter-spacing: 0em;
  text-align: left;
`;

export const introParagraph = css`
  //styleName: Paragraph/Intro;

  font-size: 2rem;
  font-weight: 600;
  line-height: 2.8rem;
  letter-spacing: -0.01em;
  text-align: left;
`;

export const regularParagraph = css`
  //styleName: Paragraph/Regular;

  font-size: 1.6rem;
  font-weight: 400;
  line-height: 2.2rem;
  letter-spacing: 0em;
  text-align: left;
`;

export const smallParagraph = css`
  //styleName: Paragraph/Small;

  font-size: 1.4rem;
  font-weight: 400;
  line-height: 2rem;
  letter-spacing: 0em;
  text-align: left;
`;

export const smallBoldParagraph = css`
  //styleName: Paragraph/Small;

  font-size: 1.4rem;
  font-weight: 600;
  line-height: 2rem;
  letter-spacing: 0em;
  text-align: left;
`;

export const extraSmallParagraph = css`
  //styleName: Paragraph/Extra Small;

  font-size: 1.2rem;
  font-weight: 400;
  line-height: 1.6rem;
  letter-spacing: 0em;
  text-align: left;
`;

export const tinySmallParagraph = css`
  //styleName: Paragraph/Extra Small;

  font-size: 1rem;
  font-weight: 400;
  line-height: 1.6rem;
  letter-spacing: 0em;
  text-align: left;
`;

export const legalParagraph = css`
  //styleName: Paragraph/Legal;

  font-size: 1rem;
  font-weight: 400;
  line-height: 1.4rem;
  letter-spacing: 0em;
  text-align: left;
`;

/* d3 ScatterChart */

export const typeStyleOpenSansScatterChartAxis = `
  font-family: ${openSansFont.style.fontFamily};
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.2rem;
  letter-spacing: 0.025rem;
`;

export const typeStyleOpenSansScatterChartText = css`
  font-size: 1.2rem;
  font-weight: 700;
  line-height: 1.6rem;
  letter-spacing: 0em;
  text-align: right;
`;

export const typeStyleOpenSansScatterChartTitle = css`
  font-size: 1.6rem;
  font-weight: 700;
  line-height: 2.4rem;
  letter-spacing: 0em;
  text-align: right;
`;

/* d3 Bar Chart */

export const typeStyleOpenSansBarChartHeader = `
  font-family: ${openSansFont.style.fontFamily};
  font-weight: 600;
  font-size: 1.4rem;
  line-height: 2rem;
  letter-spacing: 0.05rem;
`;

/* Forms */

export const typeStyleOpenSansFormHeader = css`
  font-weight: 700;
  font-size: 1.5rem;
  line-height: 1.6rem;
  letter-spacing: 0.05em;
  text-transform: capitalize;
`;

export const typeNavTabsText = css`
  font-weight: 600;
  font-size: 1.2rem;
  line-height: 2.2rem;
`;

export const typeMetricValue = css`
  text-align: right;
  font-size: 1.5rem;
  font-weight: 700;
  margin: 0;
`;

export const typeMetricText = css`
  font-size: 0.9rem;
  font-weight: 600;
  line-height: 1.6rem;
`;

export const typeChipLabel = css`
  font-size: 1.2rem;
  font-weight: 400;
  line-height: 1.8rem;
`;

export const typeSubheader = css`
  font-size: 1.4rem;
  font-weight: 600;
  line-height: 1.4rem;
`;

export const typeSmallButton = css`
  font-size: 1.4rem;
  font-weight: 600;
  line-height: 1.73rem;
`;

export const criticalHeading = css`
  font-size: 3rem;
  font-weight: 700;
  line-height: 2.2rem;
  margin-bottom: 2.5rem;
`;

export const criticalMonthCount = css`
  font-size: 5rem;
  font-weight: 300;
  line-height: 5rem;
`;

export const criticalMonth = css`
  font-size: 1.2rem;
  font-weight: 600;
  line-height: 2.4rem;
  text-transform: uppercase;
`;

export const criticalEndDate = css`
  font-size: 1.4rem;
  font-weight: 600;
  line-height: 2.4rem;
`;

export const criticalExpiresMonth = css`
  font-size: 1.4rem;
  font-weight: 600;
  line-height: 1.4rem;
`;

export const criticalDateType = css`
  font-size: 1.2rem;
  font-weight: 600;
  line-height: 1.6rem;
  text-transform: uppercase;
  letter-spacing: 0.05em;
`;

export const criticalDateCardTitle = css`
  font-size: 1.4rem;
  font-weight: 600;
  line-height: 2.2rem;
`;

export const criticalPropertyAddress = css`
  font-size: 1.2rem;
  font-weight: 400;
  line-height: 1.6rem;
`;

export const intelligentPageDataToggle = css`
  font-size: 1.4rem;
  font-weight: 600;
  line-height: 1.73rem;
`;

export const dashboardHeadersValue = css`
  font-size: 2.4rem;
  font-weight: 300;
`;
