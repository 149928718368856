import styled from '@emotion/styled';
import styledComponent from 'styled-components';
import { Menu, Typography } from '@mui/material';
import { SITE_HEADER_HEIGHT } from 'utils/layoutHelper';
import { Button } from '@ay/pascl';
import HeaderIconButton from 'components/shared/HeaderIconButton';
import { logoText } from 'styles/typography';
import { DEFAULT_CLIENT_LOGO } from './HeaderClientLogo';

export const ClientImageWrapper = styled.div`
  position: relative;
  width: 8.3rem;
  height: 2.5rem;
`;

export const HeaderClientImage = styled.div<{
  imageUrl: string | null;
}>`
  ${({ imageUrl }) => {
    return `
    background: url(${imageUrl ?? DEFAULT_CLIENT_LOGO});
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
  `;
  }};
  position: absolute;
  inset: 0;
`;

export const LogoText = styledComponent(Typography)`
  ${logoText}
`;

export const Wrapper = styled.div`
  width: 100%;
  height: ${SITE_HEADER_HEIGHT};
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 2rem;
  position: fixed;
  top: 0;
  background-color: ${(props) => props.theme.palette.white.main};
  z-index: 3;
`;

export const Left = styled.div`
  display: flex;
  gap: 2rem;
  align-items: center;
`;

export const Right = styled.div`
  display: flex;
  align-items: center;
  gap: 1.8rem;
`;

export const Controls = styled.div`
  display: flex;
  align-items: center;
  gap: 1.6rem;
`;

export const Divider = styled.div`
  height: 3.5rem;
  border-right: 1px solid ${(props) => props.theme.palette.cloudGrey.main};
`;

export const SecondaryLightTypography = styled(Typography)`
  color: ${(props) => props.theme.palette.secondary.light};
`;

export const ComponentPageHeaderContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

export const ClientLogoContainer = styled.div`
  position: relative;
  width: 7rem;
  height: 2rem;
`;

export const GlobalHeaderClientLogo = styled.div`
  position: relative;
  width: 8.3rem;
  height: 2.5rem;
`;

export const LogoContainer = styled.div`
  cursor: pointer;
  height: 8rem;
  width: 8rem;
  padding-top: 0.5rem;
`;

export const MenuStyled = styled(Menu)`
  & .MuiPaper-root {
    border-radius: 0;
  }
  & .MuiList-root {
    padding: 0;
    border-radius: 0;
  }
  & .MuiMenuItem-root {
    padding: 0.6rem 1.6rem;
    &:hover {
      background-color: ${(props) => props.theme.palette.primary.extraLight};
    }
  }
`;

export const ButtonStyled = styled(Button)`
  height: 3rem;
`;

export const PrintHeaderIconButton = styled(HeaderIconButton)`
  &.MuiIconButton-root {
    color: transparent;
  }
`;
