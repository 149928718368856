import { OktaAuth, OktaAuthOptions } from '@okta/okta-auth-js';
import { ROUTES_PATH_URLS } from 'components/routing/constants/routes';
import isSSR from '../isSSR';

class OktaAuthentication {
  public auth: OktaAuth;

  constructor(opts: OktaAuthOptions) {
    this.auth = new OktaAuth(opts);
  }
}

// OktaAuthentication client can not be initialized on the server
export default isSSR()
  ? undefined
  : new OktaAuthentication({
      issuer: process.env.NEXT_PUBLIC_OKTA_DOMAIN,
      clientId: process.env.NEXT_PUBLIC_OKTA_CLIENT_ID,
      redirectUri: window.location.origin + ROUTES_PATH_URLS.okta_callback,
      postLogoutRedirectUri: window.location.origin + ROUTES_PATH_URLS.account_sign_out,
    });
