/*
Author: Eli Elad Elrom
Component: utils/PrintHelper.tsx.tsx
*/

import format from 'date-fns/format';
import { toPng } from 'html-to-image';
import React from 'react';
import logger from './logger';

const DATE_FORMAT = 'yyMMdd_HHmmss';

export const getFileName = (fileType: string, name: string) => {
  const fileName = `AVANT ${name}`;
  const timestamp = format(new Date(), DATE_FORMAT);
  return `${fileName}_${timestamp}.${fileType}`;
};

export const downloadPng = (ref: React.RefObject<HTMLDivElement> | undefined, name: string) => {
  if (!ref || ref.current === null) {
    return;
  }
  toPng(ref.current, { cacheBust: true })
    .then((dataUrl: string) => {
      const link = document.createElement('a');
      link.download = `${getFileName('png', name)}`;
      link.href = dataUrl;
      link.click();
    })
    .catch((err) => {
      logger(err);
    });
};
