export const regex = {
  urlRegexPattern:
    /[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&/=]*)/,
  imageUrlRegexPattern: /\.(jpg|jpeg|svg|png|webp|avif|gif)$/,
  trimString: /^\s+|\s+$|\s+(?=\s)/g,
  numberWithCommas: /\B(?=(\d{3})+(?!\d))/g,
  hashDollarBrackets: /[#$]/g,
  spacesAndSymbols: /[\s.#:_&()\/']/g,
  dots: /\./g,
  allBrackets: /[\])}[{(]/g,
};
