export enum FailureCodes {
  USER_WITHOUT_CLIENTS = 'USER_WITHOUT_CLIENTS',
  USER_NOT_EXIST = 'USER_NOT_EXIST',
  SERVER_NOT_RESPOND = 'SERVER_NOT_RESPOND',
  OKTA_AUTH_FAILED = 'OKTA_AUTH_FAILED',
  GET_OKTA_TOKEN_FAILED = 'GET_OKTA_TOKEN_FAILED',
  USER_WITHOUT_DASHBOARDS = 'USER_WITHOUT_DASHBOARDS',
  ACCESS_TOKEN_EXPIRED = 'ACCESS_TOKEN_EXPIRED',
  USER_CLIENT_PERMISSION_DENIED = 'USER_CLIENT_PERMISSION_DENIED',
  OKTA_SDK_AUTH_FAILED = 'OKTA_SDK_AUTH_FAILED',
}

export const ERROR_MESSAGES = [
  {
    id: FailureCodes.USER_WITHOUT_CLIENTS,
    message: 'No user clients are attached to your user, please contact the administrator',
  },
  {
    id: FailureCodes.USER_NOT_EXIST,
    message: 'Your user is not in our system, please contact the administrator',
  },
  {
    id: FailureCodes.SERVER_NOT_RESPOND,
    message: 'Server not responding, please contact the administrator',
  },
  {
    id: FailureCodes.OKTA_SDK_AUTH_FAILED,
    message:
      'There was an issue with the Okta authentication process. Please reach out to the administrator for assistance, or attempt to sign out and sign in again.',
  },
  {
    id: FailureCodes.GET_OKTA_TOKEN_FAILED,
    message:
      'There appears to be an issue with obtaining a token from the Okta server. Please contact your administrator for assistance or try signing in again.',
  },
  {
    id: FailureCodes.USER_WITHOUT_DASHBOARDS,
    message:
      'No dashboards attached to this client, please add dashboards or contact the administrator',
  },
  {
    id: FailureCodes.ACCESS_TOKEN_EXPIRED,
    message: 'You were signed out because your session expired.',
  },
  {
    id: FailureCodes.USER_CLIENT_PERMISSION_DENIED,
    message: 'You do not have permission to view this page',
  },
];
