import { selector } from 'recoil';
import { setRecoil } from 'recoil-nexus';
import { request } from '../../../pages/api/fetchService';
import Snackbar from '../../components/shared/Toaster/ToasterWithoutState';
import { CreateDashboardDto } from 'types/dashboard';
import { HttpMethod } from 'enums/httpMethodEnum';
import { DashboardModel } from 'models';
import { dashboardModelState } from '../atoms/dashboardsAtoms';
import { getURLByEndpoint } from './SelectorsHelper';
import { API_PATH } from 'components/routing/constants/api';

const SUCCESS_MESSAGES = {
  CREATED: 'Dashboard successfully created',
  ADD_FROM_LIBRARY: (libraryName: string) =>
    `Dashboard ${libraryName} successfully added from library`,
};

export const getDashboardsRequest = (): Promise<DashboardModel[]> => {
  return request<DashboardModel[]>(
    getURLByEndpoint(API_PATH.dashboard.getAllDashboards),
    HttpMethod.GET
  );
};

export const createDashboardRequest = async (dto: CreateDashboardDto): Promise<DashboardModel> => {
  const res = await request<DashboardModel>(
    getURLByEndpoint(API_PATH.dashboard.createOne),
    HttpMethod.POST,
    dto
  );
  Snackbar.success(SUCCESS_MESSAGES.CREATED);
  return res;
};

export const createDashboardSelector = selector({
  key: 'CreateDashboardSelector',
  get: ({ getCallback }) => {
    return getCallback(() => async (dto: CreateDashboardDto) => {
      await createDashboardRequest(dto);
      setRecoil(dashboardModelState, dto);
    });
  },
});
