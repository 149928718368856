import { UserClientModel } from 'models';

export const getUserClientByActiveClientUuid = (
  userClientModels: UserClientModel[] | [],
  activeClientUuid: string
): UserClientModel | undefined => {
  return (
    userClientModels?.find((uc) => uc.clientModel.clientUuid === activeClientUuid) ?? undefined
  );
};
