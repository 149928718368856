/*
Author: Zachary Katsnelson
*/

import React, { useState, useEffect, useRef } from 'react';
import {
  WrapperOpen,
  InnerWrapper,
  Row,
  ModuleDescription,
  ModuleName,
  ModuleLogo,
} from './AppsDialogPanel.css';
import { IconButton } from '@mui/material';
import AppsIcon from '@mui/icons-material/Apps';
import { ExploreBlue, MarketselectionGreen } from 'components/ui/Svg';

const ModulesDialogPanel = () => {
  const [isOpen, setIsOpen] = useState(false);

  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (!ref.current?.contains(event.target)) {
        setIsOpen(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
  }, [ref]);

  return (
    <div ref={ref}>
      <IconButton
        onClick={() => {
          setIsOpen(!isOpen);
        }}
      >
        <AppsIcon />
      </IconButton>
      {isOpen ? (
        <WrapperOpen id="dialog-panel">
          <InnerWrapper>
            <Row>
              <ModuleLogo>
                <ExploreBlue width={'2rem'} height={'2rem'} />
              </ModuleLogo>
              <a target="_blank" href="https://avant.avisonyoung.com/" rel="noopener noreferrer">
                <ModuleName>AVANT Properties</ModuleName>
                <ModuleDescription>Description for AVANT Properties.</ModuleDescription>
              </a>
            </Row>
            <Row>
              <ModuleLogo>
                <MarketselectionGreen width={'2rem'} height={'2rem'} />
              </ModuleLogo>
              <a
                target="_blank"
                href="https://avant.avisonyoung.com/cities/"
                rel="noopener noreferrer"
              >
                <ModuleName>AVANT Cities</ModuleName>
                <ModuleDescription>Description for AVANT Cities.</ModuleDescription>
              </a>
            </Row>
          </InnerWrapper>
        </WrapperOpen>
      ) : null}
    </div>
  );
};

export default ModulesDialogPanel;
