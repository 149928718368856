import * as React from 'react';
import { SVGProps } from 'react';
const SvgLoadingIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    role="img"
    {...props}
  >
    <path d="M19 15.5V11l-7 5-7-5v4.5l7 5 7-5Z" fill="#888E9A" />
    <path d="M19 8.5V4l-7 5-7-5v4.5l7 5 7-5Z" fill="#888E9A" />
  </svg>
);
export default SvgLoadingIcon;
