import React, { useCallback, useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { FailureCodes } from 'components/pages/SomethingWentWrongPage/constants';
import { ROUTES_PATH_URLS } from 'components/routing/constants/routes';
import { useCheckUserClientsPermission } from 'components/routing/hooks/useCheckUserClientPermission';
import { getPermissionNameFromPathName } from 'components/routing/utils/permissionPathHelper';

export function UserClientPermissionCheck({ children }: { children: React.ReactNode }) {
  const { pathname, push } = useRouter();
  const [isRenderPage, setIsRenderPage] = useState(false);

  const getPermissionNameFromPathname = useCallback(() => {
    return getPermissionNameFromPathName(pathname);
  }, [pathname]);

  const path = getPermissionNameFromPathname();
  const isAllowedToViewOrEdit = useCheckUserClientsPermission(path.toLowerCase());

  useEffect(() => {
    const isUserClientPermissionWasChecked = isAllowedToViewOrEdit !== null;
    if (!isUserClientPermissionWasChecked) return;

    if (!isAllowedToViewOrEdit) {
      push(ROUTES_PATH_URLS.something_went_wrong(FailureCodes.USER_CLIENT_PERMISSION_DENIED));
    }

    setIsRenderPage(true);
  }, [isAllowedToViewOrEdit, path]);

  return isRenderPage && isAllowedToViewOrEdit ? <>{children}</> : null;
}
