/*
Author: Eli Elad Elrom
Component: src/utils/Formatters.ts

Usage example:
  const date = getFormatter('formatDateDayMonthYear')(new Date().toISOString()).toUpperCase();

*/

import { getRecoil } from 'recoil-nexus';
import { userModelState } from '../recoil/atoms/userAtoms';
import { formatNumberAsKiloMillion } from '../utils/numHelper';
import {
  addCurrencySymbolSign,
  addCurrencySymbolWithCommasAndScale,
  addCurrencySymbolWithCommasBasedOnUserCurrency,
  addCurrencySymbolWithTwoDecimal,
  addPercentage,
  capitalizeEachWord,
  convertFloatToNumberWithCommasWithNoDecimal,
  convertFloatToPercentage,
  currencySymbolWithCommas,
  floatFourDecimalWithPercentage,
  floatOneDecimal,
  floatOneDecimalWithPercentage,
  floatWithNoDecimal,
  formatDateRemainYearMonth,
  integerWithCommas,
  numberWithCommas,
  sqfNumberWithCommas,
  sqfNumberWithUpperCase,
  trimStringWithSpaces,
} from '../utils/formattersFunctions';
import { parseDate } from './datesHelper';

export enum FormatterType {
  FORMAT_DATE_DAY_MONTH_YEAR = 'formatDateDayMonthYear',
  FORMAT_DATE_DAY_SHORT_MONTH_YEAR = 'formatDateDayShortMonthYear',
  FORMAT_DATE_REMAIN_YEAR_MONTH = 'formatDateRemainYearMonth',
  FORMAT_DATE_MONTH_DAY_YEAR_SLASH = 'formatDateMonthDayYearSlash',
  SQF_NUMBER_WITH_COMMAS = 'sqfNumberWithCommas',
  SQF_NUMBER_WITH_UPPER_CASE = 'sqfNumberWithUpperCase',
  ADD_CURRENCY_SYMBOL_SIGN = 'addCurrencySymbolSign',
  ADD_CURRENCY_SYMBOL_WITH_TWO_DECIMAL = 'addCurrencySymbolWithTwoDecimal',
  ADD_CURRENCY_SYMBOL_WITH_COMMAS_AND_SCALE = 'addCurrencySymbolWithCommasAndScale',
  ADD_CURRENCY_SYMBOL_WITH_COMMAS_BASED_ON_USER_CURRENCY = 'addCurrencySymbolWithCommasBasedOnUserCurrency',
  CURRENCY_SYMBOL_WITH_COMMAS = 'currencySymbolWithCommas',
  NUMBER_WITH_COMMAS = 'numberWithCommas',
  INTEGER_WITH_COMMAS = 'integerWithCommas',
  ADD_PERCENTAGE = 'addPercentage',
  FLOAT_ONE_DECIMAL = 'floatOneDecimal',
  FLOAT_FOUR_DECIMAL_WITH_PERCENTAGE = 'floatFourDecimalWithPercentage',
  FLOAT_ONE_DECIMAL_WITH_PERCENTAGE = 'floatOneDecimalWithPercentage',
  FLOAT_WITH_NO_DECIMAL = 'floatWithNoDecimal',
  CONVERT_FLOAT_TO_PERCENTAGE = 'convertFloatToPercentage',
  CONVERT_FLOAT_TO_NUMBER_WITH_COMMAS_WITH_NO_DECIMAL = 'convertFloatToNumberWithCommasWithNoDecimal',
  CAPITALIZE_EACH_WORD = 'capitalizeEachWord',
  TRIM_STRING_WITH_SPACES = 'trimStringWithSpaces',
  TRIM_LONG_TEXT = 'trimLongText',
  FORMAT_NUMBER_AS_KILO_MILLION = 'formatNumberAsKiloMillion',
}

interface CurrencyMap {
  [key: string]: string;
}

const currencySymbolMap: CurrencyMap = {
  USD: '$',
  EUR: '€',
  JPY: '¥',
  GBP: '£',
  AUD: 'A$',
  CAD: 'C$',
  CHF: 'CHF',
  CNY: '¥',
  HKD: 'HK$',
  NZD: 'NZ$',
  SEK: 'kr',
  KRW: '₩',
  SGD: 'S$',
  NOK: 'kr',
  MXN: 'Mex$',
  INR: '₹',
  RUB: '₽',
  ZAR: 'R',
  TRY: '₺',
  BRL: 'R$',
  TWD: 'NT$',
  DKK: 'kr',
};

export const getFormatter = (functionName: string) => {
  const userModel = getRecoil(userModelState);

  let currencySymbol = '$';

  if (userModel) {
    const currencyCode = userModel?.selectedUserClientModel?.currencyCode || 'USD';
    currencySymbol = currencySymbolMap[currencyCode] || '$';
  }

  let retVal = (num: any) => num;

  switch (functionName) {
    case FormatterType.FORMAT_DATE_DAY_MONTH_YEAR:
      retVal = (date: string) => parseDate(date, 'dd-MMM-yyyy');
      break;
    case FormatterType.FORMAT_DATE_DAY_SHORT_MONTH_YEAR:
      retVal = (date: string) => parseDate(date, 'dd-LLL-yyyy');
      break;
    case FormatterType.FORMAT_DATE_MONTH_DAY_YEAR_SLASH:
      retVal = (date: string) => parseDate(date, 'MM/dd/yyyy');
      break;
    case FormatterType.FORMAT_DATE_REMAIN_YEAR_MONTH:
      retVal = formatDateRemainYearMonth;
      break;
    case FormatterType.SQF_NUMBER_WITH_COMMAS:
      retVal = sqfNumberWithCommas;
      break;
    case FormatterType.SQF_NUMBER_WITH_UPPER_CASE:
      retVal = sqfNumberWithUpperCase;
      break;
    case FormatterType.ADD_CURRENCY_SYMBOL_SIGN:
      retVal = (num: number) => addCurrencySymbolSign(num, currencySymbol);
      break;
    case FormatterType.ADD_CURRENCY_SYMBOL_WITH_TWO_DECIMAL:
      retVal = (val: string) => addCurrencySymbolWithTwoDecimal(val, currencySymbol);
      break;
    case FormatterType.ADD_CURRENCY_SYMBOL_WITH_COMMAS_AND_SCALE:
      retVal = (val: string) => addCurrencySymbolWithCommasAndScale(val, currencySymbol);
      break;
    case FormatterType.ADD_CURRENCY_SYMBOL_WITH_COMMAS_BASED_ON_USER_CURRENCY:
      retVal = (val: string) => addCurrencySymbolWithCommasBasedOnUserCurrency(val, currencySymbol);
      break;
    case FormatterType.CURRENCY_SYMBOL_WITH_COMMAS:
      retVal = (val: string) => currencySymbolWithCommas(val, currencySymbol);
      break;
    case FormatterType.NUMBER_WITH_COMMAS:
      retVal = numberWithCommas;
      break;
    case FormatterType.INTEGER_WITH_COMMAS:
      retVal = integerWithCommas;
      break;
    case FormatterType.ADD_PERCENTAGE:
      retVal = addPercentage;
      break;
    case FormatterType.FLOAT_FOUR_DECIMAL_WITH_PERCENTAGE:
      retVal = floatFourDecimalWithPercentage;
      break;
    case FormatterType.FLOAT_ONE_DECIMAL:
      retVal = floatOneDecimal;
      break;
    case FormatterType.FLOAT_ONE_DECIMAL_WITH_PERCENTAGE:
      retVal = floatOneDecimalWithPercentage;
      break;
    case FormatterType.FLOAT_WITH_NO_DECIMAL:
      retVal = floatWithNoDecimal;
      break;
    case FormatterType.CONVERT_FLOAT_TO_PERCENTAGE:
      retVal = convertFloatToPercentage;
      break;
    case FormatterType.CONVERT_FLOAT_TO_NUMBER_WITH_COMMAS_WITH_NO_DECIMAL:
      retVal = convertFloatToNumberWithCommasWithNoDecimal;
      break;
    case FormatterType.CAPITALIZE_EACH_WORD:
      retVal = capitalizeEachWord;
      break;
    case FormatterType.TRIM_STRING_WITH_SPACES:
      retVal = trimStringWithSpaces;
      break;
    case FormatterType.FORMAT_NUMBER_AS_KILO_MILLION:
      retVal = formatNumberAsKiloMillion;
      break;
  }
  return retVal;
};
