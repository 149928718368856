import { useRecoilValue } from 'recoil';
import { regex } from 'constants/regex';
import { userModelState } from 'recoil/atoms/userAtoms';
import React, { useEffect, useState } from 'react';
import { ClientImageWrapper, HeaderClientImage } from './SiteHeader.css';
import { APP_CONFIG } from 'constants/config';

export const DEFAULT_CLIENT_LOGO = `${APP_CONFIG.basePath}/images/clients/AVYLogo.svg`;

const HeaderClientLogo = () => {
  const userModel = useRecoilValue(userModelState);
  const selectedClientLogo = userModel?.selectedUserClientModel?.clientModel?.clientHeadLogo;
  const [clientLogo, setClientLogo] = useState<null | string>(null);

  const isValidUrl = (urlString: string) => regex.imageUrlRegexPattern.test(urlString);

  useEffect(() => {
    setClientLogo(
      selectedClientLogo && isValidUrl(selectedClientLogo)
        ? selectedClientLogo
        : DEFAULT_CLIENT_LOGO
    );
  }, [selectedClientLogo]);

  return (
    <ClientImageWrapper>
      <HeaderClientImage imageUrl={clientLogo} />
    </ClientImageWrapper>
  );
};

export default HeaderClientLogo;
