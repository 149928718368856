import { PermissionStatusEnum } from 'components/routing/enums/userClientPermissionEnum';
import { ROUTES_PATH_URLS } from 'components/routing/constants/routes';
import { IUserClientPermission } from 'models';
import { v4 as uuid } from 'uuid';

export const PERMISSIONS_OPTIONS = [
  { value: PermissionStatusEnum.NONE, label: 'None' },
  { value: PermissionStatusEnum.VIEW, label: 'View' },
  { value: PermissionStatusEnum.VIEW_OR_EDIT, label: 'View/Edit' },
];

export const INIT_USER_CLIENT_PERMISSIONS = [
  {
    uuid: uuid(),
    name: 'Property Detail',
    status: '',
    permissionUrl: ROUTES_PATH_URLS.property_profile,
  },
  {
    uuid: uuid(),
    name: 'Critical Date',
    status: '',
    permissionUrl: ROUTES_PATH_URLS.critical_date,
  },
] as IUserClientPermission[];
