/*
Author: Eli Elad Elrom
Component: src/model/printObject.ts
*/

import React from 'react';

// eslint-disable-next-line @typescript-eslint/naming-convention
export interface PrintModel {
  printRef: React.RefObject<HTMLDivElement> | undefined;
  dashboardRef: React.RefObject<HTMLDivElement> | undefined;
}

export const initPrintObject = (): PrintModel => ({
  printRef: undefined,
  dashboardRef: undefined,
});
