import * as React from 'react';
import { SVGProps } from 'react';
const SvgArrowRight = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    role="img"
    {...props}
  >
    <path
      d="m6 .667-.94.94 3.72 3.726H.667v1.334H8.78l-3.72 3.726.94.94L11.333 6 6 .667Z"
      fill="currentColor"
    />
  </svg>
);
export default SvgArrowRight;
