/*
Author: Eli Elad Elrom
Component: src/components/DashboardPage/WidgetsType.ts
*/

export enum DataSetTypeEnum {
  V_PROPERTY = 'vProperty',
  V_LEASE = 'vLease',
}

export enum MainWidgetsTypeEnum {
  SCATTER_RENT_VACANCY = 'scatterRentVacancy',
  SCATTER_COST_OCCUPANCY_DASHBOARD = 'scatterCostOccupancyDashboard',
  SCATTER_OPPORTUNITIES_AND_RISKS_DASHBOARD = 'scatterOpportunitiesAndRisksDashboard',
  SCATTER_REMAINING_MONTHS_TO_EXPIRATION = 'scatterRemainingMonthsToExpiration',
  OPPORTUNITIES_RISKS_EXPIRE_CHART = 'opportunitiesRisksExpireChart',
  HORIZONTAL_BAR_CHART = 'horizontalBarChart',
  WORLD_MAP = 'worldMap',
  MAP_BOX = 'mapBox',
  MAP_BOX_AND_SINGLE_STACK_BAR_WIDGET = 'mapBoxAndSingleStackBar',
  HEAT_MAP = 'heatMap',
  PERFORMANCE_DIAMOND = 'performanceDiamond',
  SCATTER_METRIC_OUTLIER_ANALYSIS = 'scatterMetricOutlierAnalysis',
  LEASE_LIST = 'leaseList',
  LEASE_EXPIRATIONS = 'leaseExpirations',
  PROPERTY_COST_PARETO = 'propertyCostPareto',
  OCCUPANCY_METRIC_DISTRIBUTION = 'occupancyMetricDistribution',
  TABLEAU = 'TABLEAU',
  SISENSE = 'SISENSE',
  GEOGRAPHIC_HIERARCHY_TREE_MAP = 'geographicHierarchyTreemap',
  PORTFOLIO_OVERVIEW_BAR_CHART = 'portfolioOverviewBarChart',
  LEASE_OVERVIEW_BAR_CHART = 'leaseOverviewBarChart',
  LEASE_OVERVIEW_PIE_CHART = 'leaseOverviewPieChart',
  M2M = 'm2m',
  LEASE_EXPIRATIONS_DASHBOARD = 'leaseExpirationsDashboard',
  NONE = 'none',
}

export enum RightWidgetsTypeEnum {
  TOTAL_COST_BY_COUNTRY = 'totalCostByCountry',
  PORTFOLIO_OVERVIEW_BAR_CHART = 'portfolioOverviewBarChart',
  PORTFOLIO_TABLE = 'portfolioTable',
  TOTAL_COST_BY_PROPERTY = 'totalCostByProperty',
  MARKET_TO_MARKET_BAR_CHART = 'marketToMarketBarChart',
  METRIC_OUTLIER_ANALYSIS_BARS = 'metricOutlierAnalysisBars',
  MAP_BOX = 'mapBox',
  MAP_BOX_SCATTER = 'mapBoxScatter',
  NONE = 'none',
}

export enum FilterTypeEnum {
  ACCESSORS = 'accessors',
  METRICS_FILTERS = 'metricsFilters',
  MOR_FILTERS = 'morFilters',
  NONE = 'none',
}

export enum BottomMainWidgetsTypeEnum {
  LEASE_REMAINING_MONTHS_TO_EXPIRATION = 'leaseRemainingMonthsToExpiration',
  BOTTOM_PROPERTY_COST_PARETO = 'bottomPropertyCostPareto',
  HORIZONTAL_STACKED_BAR = 'horizontalStackedBar',
  SINGLE_STACKED_BAR = 'singleStackedBar',
  NONE = 'none',
}

export enum SelectedMainHeaderTypeEnum {
  NONE = 'none',
  METRICS_HEADER = 'metricsHeader',
  LEASE_METRICS_HEADER = 'leaseMetricsHeader',
  MOR_HEADER = 'morHeader',
}

export enum MetricTypeEnum {
  X_ACCESSOR = 'xAccessor',
  Y_ACCESSOR = 'yAccessor',
  Z_ACCESSOR = 'zAccessor',
  XY_ACCESSOR = 'xyAccessor',
}
