import * as React from 'react';
import { SVGProps } from 'react';
const SvgArrowTop = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    role="img"
    {...props}
  >
    <path
      d="m.667 6 .94.94 3.726-3.72v8.113h1.334V3.22l3.72 3.727.946-.947L6 .667.667 6Z"
      fill="#3A4458"
    />
  </svg>
);
export default SvgArrowTop;
