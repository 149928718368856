import { Button, IconButton } from '@ay/pascl';
import { Controls, Divider, Left, LogoContainer, LogoText, Right, Wrapper } from './SiteHeader.css';
import { Segment } from '@mui/icons-material';
import React from 'react';
import { AYLogo } from '../../shared/AYLogo';
import { useRouter } from 'next/router';
import { NextPublicEnvironmentEnum } from 'enums/nextPublicEnvironmentEnum';
import { ROUTES_PATH_URLS } from 'components/routing/constants/routes';

const LogoutHeader = ({ pageTitle }: LogoutHeaderProps) => {
  const router = useRouter();

  return (
    <Wrapper>
      <Left>
        <LogoContainer onClick={() => router.push(ROUTES_PATH_URLS.home)}>
          <AYLogo />
        </LogoContainer>
        <Divider />
        <LogoText>{pageTitle}</LogoText>
      </Left>
      <Right>
        <Button
          onClick={
            process.env.NEXT_PUBLIC_ENVIRONMENT === NextPublicEnvironmentEnum.LOCAL_SKIP_OKTA
              ? () => router.push(ROUTES_PATH_URLS.okta_callback)
              : () => router.push(ROUTES_PATH_URLS.account_sign_in)
          }
        >
          Sign In
        </Button>
        <Controls>
          <IconButton>
            <Segment />
          </IconButton>
        </Controls>
      </Right>
    </Wrapper>
  );
};

export interface LogoutHeaderProps {
  pageTitle: string;
}

export default LogoutHeader;
