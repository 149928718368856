import { Icon, IconProps } from '@mui/material';
import styled from '@emotion/styled';

export const StyledIcon = styled(Icon)(
  (props) => `
    & .MuiSvgIcon-root {
      color: ${props.theme.palette.secondary.light};
    }
  `
);

const LightGreyIcon = (props: IconProps) => {
  return <StyledIcon {...props} />;
};

export default LightGreyIcon;
