import * as React from 'react';
import { SVGProps } from 'react';
const SvgMarketselectionGreen = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    role="img"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.667 4.667V1.333h6v13.334h-6v-10ZM7.333 4V.667C7.333.298 7.632 0 8 0h7.333c.368 0 .667.298.667.667v14.666a.667.667 0 0 1-.667.667H.667A.667.667 0 0 1 0 15.333V4.667C0 4.298.298 4 .667 4h6.666Zm0 1.333h-6v9.334h6V5.333Zm-4 6.667a.667.667 0 0 0 0 1.333h2a.667.667 0 1 0 0-1.333h-2Zm-.666-2c0-.368.298-.667.666-.667h2a.667.667 0 0 1 0 1.334h-2A.667.667 0 0 1 2.667 10Zm.666-3.333a.667.667 0 1 0 0 1.333h2a.667.667 0 0 0 0-1.333h-2Zm7.334 4.666a.667.667 0 0 0 0 1.334h2a.667.667 0 0 0 0-1.334h-2Zm-.667-2c0-.368.299-.666.667-.666h2a.667.667 0 1 1 0 1.333h-2A.667.667 0 0 1 10 9.333ZM10.667 6a.667.667 0 1 0 0 1.333h2a.667.667 0 1 0 0-1.333h-2ZM10 4c0-.368.299-.667.667-.667h2a.667.667 0 0 1 0 1.334h-2A.667.667 0 0 1 10 4Z"
      fill="#4DB595"
    />
  </svg>
);
export default SvgMarketselectionGreen;
