/*
Author: Eli Elad Elrom
Component: src/utils/numHelper.ts
*/

import { regex } from '../constants/regex';

export const numParser = (d: string) => {
  let retVal = d;
  try {
    parseFloat(d.replace(regex.hashDollarBrackets, ''));
  } catch {}
  return retVal;
};

export const floatNumberWithCommas = (x: { toString: () => string }) =>
  x.toString().replace(regex.numberWithCommas, ',');

export const intNumberWithCommas = (x: { toString: () => string }) =>
  parseInt(x.toString()).toString().replace(regex.numberWithCommas, ',');

export const numOfDigits = (num: number): number => Math.ceil(num).toString().length;

export const formatNumberAsKiloMillion = (num: number): string =>
  Intl.NumberFormat('en', { notation: 'compact' }).format(num);

export const parseNumOrStringAsFloat = (val: number | string): number => {
  const parseNumOrStringAsFloatRegex = /[$#()\[\],]/g;
  const parsedNum = parseFloat(val.toString().replace(parseNumOrStringAsFloatRegex, ''));

  return isNaN(parsedNum) ? 0 : parsedNum;
};

export const calculateDivision = (numerator: number, denominator: number) => {
  if ((numerator === 0 && denominator === 0) || denominator === 0) {
    return 0;
  }
  return numerator / denominator;
};

export const isDivisibleByNumber = (number: number, divisor: number): boolean =>
  number % divisor === 0;
