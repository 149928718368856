import { css } from 'styled-components';

export enum BreakpointsEnum {
  TABLET_MAX_WIDTH = 1060,
  MOBILE_MAX_WIDTH = 960,
}

export const breakpoints = {
  desktopOnly: `@media (min-width: ${BreakpointsEnum.MOBILE_MAX_WIDTH}px)`,
  tablet: `screen and (max-width: ${BreakpointsEnum.TABLET_MAX_WIDTH}px)`,
  mobile: `screen and (max-width: ${BreakpointsEnum.MOBILE_MAX_WIDTH}px)`,
};

export const breakpoint =
  (key: keyof typeof breakpoints) =>
  (...args: Parameters<typeof css>) => {
    const styles = css(...args);
    return (props: { theme: { breakpoints: typeof breakpoints } }) =>
      css`
        @media ${props.theme.breakpoints[key]} {
          ${styles}
        }
      `;
  };

export default breakpoints;
