import React from 'react';
import { CustomContentProps, VariantType, closeSnackbar } from 'notistack';
import {
  ToastContainer,
  StyledSnackbarContent,
  TitleAndButton,
  StyledTextButton,
} from './Toaster.css';
import { Check, ErrorOutline, WarningOutlined, InfoOutlined } from '@mui/icons-material';
import { Typography } from '@mui/material';
import ToasterCloseButton from './ToasterCloseButton';

const renderIcon = (variant: VariantType) => {
  switch (variant) {
    case 'success':
      return <Check color="success" />;
    case 'error':
      return <ErrorOutline color="error" />;
    case 'warning':
      return <WarningOutlined color="warning" />;
    case 'info':
      return <InfoOutlined color="info" />;
  }
};

const CustomToast = React.forwardRef<HTMLDivElement, CustomToastProps>((props, ref) => {
  const { message, alertContent = '', variant, id, actionButtonProps, ...other } = props;
  return (
    <ToastContainer colorVariant={variant as unknown as VariantType}>
      {/* https://github.com/iamhosseindhv/notistack/issues/485 */}
      {/* @ts-ignore */}
      <StyledSnackbarContent ref={ref} role="alert" {...other}>
        {renderIcon(variant)}
        <div>
          <TitleAndButton>
            <Typography>{message}</Typography>
            {actionButtonProps && (
              <StyledTextButton color={variant} onClick={actionButtonProps.callback}>
                {actionButtonProps.title}
              </StyledTextButton>
            )}
          </TitleAndButton>
          <Typography variant="smallParagraph">{alertContent}</Typography>
        </div>
        <ToasterCloseButton variant={variant} onClick={() => closeSnackbar(id)} />
      </StyledSnackbarContent>
    </ToastContainer>
  );
});

interface CustomToastProps extends CustomContentProps {
  alertContent?: string;
  actionButtonProps?: {
    title: string;
    callback: () => void;
  };
}

export default CustomToast;
