import Snackbar from 'components/shared/Toaster/ToasterWithoutState';
import React, { useEffect } from 'react';
import { useRecoilState, useRecoilValueLoadable } from 'recoil';
import {
  CLIENT_DASHBOARD_SUCCESS_MESSAGES,
  getClientDashboardsBasedOnClientSelector,
} from 'recoil/selectors/clientDashboardSelectors';
import { RecoilStateEnum } from 'enums/recoilState.enum';
import { userModelState } from 'recoil/atoms/userAtoms';
import { ClientDashboardsResults } from 'types/client-dashboard';
import { formatClientDashboardsWithBookmarks } from 'utils/cliendDashboardHelper';
import { useRouter } from 'next/router';

function UpdateClientDashboardComponent({ onComplete }: Readonly<{ onComplete: () => void }>) {
  const router = useRouter();
  const queryString = require('query-string');
  router.query = queryString.parse(router.asPath.split(/\?/)[1]);
  const dashboardUuid = String(router.query.id);
  const { contents, state } = useRecoilValueLoadable(
    getClientDashboardsBasedOnClientSelector
  ) as ClientDashboardsResults;

  const [userModel, setUserModel] = useRecoilState(userModelState);

  useEffect(() => {
    if (state === RecoilStateEnum.HAS_VALUE) {
      const formattedClientDashboardsWithBookmarks = formatClientDashboardsWithBookmarks(
        contents,
        userModel.clientDashboardBookmarkModels
      );
      setUserModel((prevUser) => ({
        ...prevUser,
        clientDashboardModels: formattedClientDashboardsWithBookmarks,
        selectedClientDashboardModel:
          formattedClientDashboardsWithBookmarks?.find(
            (cld) => cld.dashboardUuid === dashboardUuid
          ) ?? prevUser.selectedClientDashboardModel,
      }));

      onComplete();
      Snackbar.success(
        `${CLIENT_DASHBOARD_SUCCESS_MESSAGES.CLIENT_SWITCHED} to ${userModel.selectedUserClientModel?.clientModel.clientId}`
      );
    }
  }, [
    onComplete,
    contents,
    state,
    userModel.clientDashboardBookmarkModels,
    userModel.selectedUserClientModel?.clientModel.clientId,
  ]);

  return <></>;
}

export default UpdateClientDashboardComponent;
