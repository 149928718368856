import { IconButton } from '@ay/pascl';
import { Avatar, MenuItem } from '@mui/material';
import AppsDialogPanel from 'components/dialogs/AppsDialogPanel';
import { useRouter } from 'next/router';
import React, { useEffect, useState } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { printState } from 'recoil/atoms/printAtoms';
import { userModelState } from 'recoil/atoms/userAtoms';
import { sortMultiArrayAscending } from 'utils/d3Helper';
import { AYLogo } from '../../shared/AYLogo';
import {
  ButtonStyled,
  Controls,
  Divider,
  Left,
  LogoContainer,
  LogoText,
  MenuStyled,
  Right,
  SecondaryLightTypography,
  Wrapper,
} from './SiteHeader.css';
import UpdateClientDashboardComponent from './UpdateClientDashboardComponent';
import HomePageLink from './HomePageLink';
import { Menu, MenuOption } from 'components/shared/Menu';
import HeaderClientLogo from 'components/layout/SiteHeader/HeaderClientLogo';
import { ROUTES_PATH_URLS } from 'components/routing/constants/routes';
import { updateClientSelector } from 'recoil/selectors/clientsSelectors';

const DefaultGlobalHeader = ({ pageTitle }: DefaultGlobalHeaderProps) => {
  const router = useRouter();
  const userModel = useRecoilValue(userModelState);
  const updateClient = useRecoilValue(updateClientSelector);
  const selectedClientUnitMeas = userModel?.selectedUserClientModel?.unitMeas;
  const selectedClientCurrencyCode = userModel?.selectedUserClientModel?.currencyCode;
  const [options, setOptions] = useState<MenuOption[]>([]);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [ref] = useRecoilState(printState);

  const menuOpen = Boolean(anchorEl);
  const handleMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const signOut = async () => {
    await router.push(ROUTES_PATH_URLS.account_sign_out);
    handleMenuClose();
  };

  const redirectToPreferencesPage = async () => {
    await router.push(ROUTES_PATH_URLS.preferences);
    handleMenuClose();
  };

  const redirectToAdminPage = async () => {
    await router.push(ROUTES_PATH_URLS.admin_users);
    handleMenuClose();
  };

  const [changeDashboardsByClient, setChangeDashboardsByClient] = useState(false);

  useEffect(() => {
    if (userModel.userClientModels) {
      const opt: MenuOption[] = [];
      userModel.userClientModels.forEach((dt) => {
        opt.push({
          value: dt.clientModel.clientId,
          label: dt.clientModel.clientName,
          id: dt.clientModel.clientUuid,
        });
      });
      setOptions(sortMultiArrayAscending(opt, 'value'));
    }
  }, [userModel.userClientModels]);

  const onClientChange = (clientUuid: string) => {
    updateClient(clientUuid);
    setChangeDashboardsByClient(true);
  };

  const onLogoClickHandler = () => {
    if (ref.dashboardRef) {
      window.location.href = ROUTES_PATH_URLS.home;
    } else {
      router.push(ROUTES_PATH_URLS.home);
    }
  };

  return (
    <>
      {changeDashboardsByClient && (
        <UpdateClientDashboardComponent onComplete={() => setChangeDashboardsByClient(false)} />
      )}
      <Wrapper>
        <Left>
          <LogoContainer id="logo-container" onClick={onLogoClickHandler}>
            <AYLogo />
          </LogoContainer>
          <Divider />
          <LogoText>{pageTitle}</LogoText>
        </Left>
        <Right>
          <Controls>
            <HomePageLink />
            <Divider />
            {userModel?.selectedUserClientModel && (
              <SecondaryLightTypography variant="smallParagraph">
                {selectedClientCurrencyCode} | {selectedClientUnitMeas}
              </SecondaryLightTypography>
            )}
            <IconButton
              id="profile-button"
              aria-controls={menuOpen ? 'profile-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={menuOpen ? 'true' : undefined}
              onClick={handleMenuClick}
            >
              <Avatar>{userModel.userInitial}</Avatar>
            </IconButton>
            <MenuStyled
              id="profile-menu"
              anchorEl={anchorEl}
              open={menuOpen}
              onClose={handleMenuClose}
              MenuListProps={{
                'aria-labelledby': 'profile-button',
              }}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
            >
              <MenuItem onClick={redirectToPreferencesPage}>Preferences</MenuItem>
              <MenuItem onClick={redirectToAdminPage}>Admin</MenuItem>
              <MenuItem onClick={handleMenuClose}>
                <ButtonStyled type="submit" onClick={signOut}>
                  Sign Out
                </ButtonStyled>
              </MenuItem>
            </MenuStyled>
          </Controls>
          <AppsDialogPanel />
          <Menu options={options} onClickItem={onClientChange} targetNode={<HeaderClientLogo />} />
        </Right>
      </Wrapper>
    </>
  );
};

export interface DefaultGlobalHeaderProps {
  pageTitle: string;
}

// @ts-ignore
export default DefaultGlobalHeader;
