import styled from '@emotion/styled';
import { Card, Button } from '@mui/material';
import { SnackbarContent, VariantType } from 'notistack';
import { ColorVariant } from 'types/material-ui';
import Close from '@mui/icons-material/Close';

export const SnackbarContainer = styled.div`
  .notistack-Snackbar {
    top: 1.2rem;
    right: 1.2rem;
  }
`;

export const ToastContainer = styled(Card)<{ colorVariant: VariantType }>`
  width: 32rem;
  padding: 1rem;
  border: 1px solid ${(props) => props.theme.palette[props.colorVariant as ColorVariant].main};
  color: ${(props) => props.theme.palette[props.colorVariant as ColorVariant].extraDark};
`;

export const StyledSnackbarContent = styled(SnackbarContent)`
  // !important declaration needed here for SnackbarContent to honour the display property override
  display: grid !important;
  grid-template-columns: 1fr 8fr 1fr;
  gap: 1rem;
`;

export const TitleAndButton = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const StyledClose = styled(Close)`
  cursor: pointer;
  justify-self: flex-end;
`;

export const StyledTextButton = styled(Button)`
  padding: 0;
`;
StyledTextButton.defaultProps = {
  size: 'small',
};
